import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import config from "../../config";
import {
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaEnvelope,
  FaInstagram,
} from "react-icons/fa";
import "./BlogDetail.css";

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  const currentUrl = window.location.href;

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/api/blogs/${id}`
        );
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchBlog();
  }, [id]);

  return (
    <div className="blog-detail-main-container">
      <Navbar />
      <div className="blog-detail">
        {loading ? (
          <div className="loading-indicator">Loading...</div>
        ) : blog ? (
          <>
            <img src={blog.imageUrl} alt={blog.title} />
            <h1>{blog.title}</h1>
            <p className="blog-date">
              {new Date(blog.date).toLocaleDateString()}
            </p>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            />
            {/* Social Links */}
            <div className="blog-social-links">
              <span>Share this blog:</span>
              <a
                href={`https://www.instagram.com/direct/new/?text=${encodeURIComponent(
                  `Check out this blog: ${currentUrl}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Instagram DM"
              >
                <FaInstagram />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  currentUrl
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Facebook"
              >
                <FaFacebook />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  currentUrl
                )}&text=${encodeURIComponent(blog.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Twitter"
              >
                <FaTwitter />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                  currentUrl
                )}&title=${encodeURIComponent(blog.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on LinkedIn"
              >
                <FaLinkedin />
              </a>
              <a
                href={`mailto:?subject=${encodeURIComponent(
                  "Check out this blog"
                )}&body=${encodeURIComponent(
                  `I found this blog interesting: ${currentUrl}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share via Email"
              >
                <FaEnvelope />
              </a>
            </div>
          </>
        ) : (
          <div className="error-message">Unable to load blog details.</div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
