import React, { useState, useContext } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Login.css";
import SignupModal from "../../components/SignupModal/SignupModal";
import logo from "../../assets/images/header-logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the redirect path from location state or default to "/"
  const redirectPath = location.state?.from || "/";

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const normalizedEmail = email.trim().toLowerCase();
    try {
      // Prepare credentials object
      const credentials = { email, password };

      // Call the login method from context to store user and token globally
      await login(credentials);

      // Success message
      setSuccess("Login successful! Redirecting...");
      setError("");

      setTimeout(() => {
        navigate(redirectPath);
        // localStorage.removeItem("kitchenFormData");
      }, 1000);
    } catch (error) {
      console.error(
        "Error during login:",
        error.response ? error.response.data : error.message
      );
      setError("Invalid credentials!");
      setSuccess("");
    }
  };

  const toggleSignUpModal = () => {
    setIsSignUp(!isSignUp);
  };

  return (
    <div className="login-page">
      <div className="login-page__container">
        <a href="/" className="login-page__logo-link">
          <img src={logo} alt="Logo" className="login-page__logo-image" />
        </a>

        <h2 className="login-page__title">Login to Your Account</h2>
        {error && <p className="login-page__error-message">{error}</p>}
        {success && <p className="login-page__success-message">{success}</p>}
        <form className="login-page__form" onSubmit={handleSubmit}>
          <div className="login-page__form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-page__input"
              aria-label="Email Address"
            />
          </div>
          <div className="login-page__form-group password-group">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-page__input"
              aria-label="Password"
            />
            <span
              className="password-toggle-icon"
              onClick={togglePasswordVisibility}
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button type="submit" className="login-page__login-btn">
            Login
          </button>
        </form>
        <p className="login-page__signup-link">
          Don't have an account?{" "}
          <span onClick={toggleSignUpModal} className="login-page__signup-text">
            Sign Up
          </span>
        </p>
      </div>

      {isSignUp && <SignupModal onClose={toggleSignUpModal} />}
    </div>
  );
};

export default Login;
