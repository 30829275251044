import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./TermsOfUse.css";

const TermsOfUse = () => {
  return (
    <div className="TOU-main-container">
      <Navbar />
      <div className="TOU-container">
        <h2>AirKitchenz AI Terms of Use</h2>
        <p className="TOU-effective-date">
          Effective Date: <strong>November 20, 2024</strong>
        </p>
        <div className="TOU-content">
          <p>
            Welcome to <strong>AirKitchenz</strong>. By using our platform, you
            agree to the following terms:
          </p>
          <ol>
            <li>
              <strong>Acceptable Use:</strong> You agree to use the platform
              responsibly and not to:
              <ul>
                <li>Post false or misleading information.</li>
                <li>Violate intellectual property rights.</li>
                <li>Interfere with the operation of the platform.</li>
              </ul>
            </li>
            <li>
              <strong>Account Responsibilities:</strong> You are responsible for
              maintaining the confidentiality of your account and password.
              Notify us immediately of unauthorized account access.
            </li>
            <li>
              <strong>User Content:</strong> By submitting content to{" "}
              <strong>AirKitchenz</strong>, you grant us a license to use,
              modify, and display it as necessary for platform operation.
            </li>
            <li>
              <strong>Platform Changes:</strong> We may modify or discontinue
              the platform at any time without prior notice.
            </li>
            <li>
              <strong>Dispute Resolution:</strong> These terms are governed by
              the laws of the State of Delaware, USA, with any disputes resolved
              in accordance with Delaware law. For operational disputes,
              jurisdiction may also include Los Angeles County, California, USA.
            </li>
            <li>
              <strong>Indemnification:</strong> Users agree to indemnify,
              defend, and hold harmless <strong>AirKitchenz</strong>, its
              affiliates, officers, employees, and agents from any claims,
              damages, losses, or expenses (including legal fees) arising from
              their use of the platform, breach of these terms, or violation of
              applicable laws.
            </li>
            <li>
              <strong>Force Majeure:</strong> <strong>AirKitchenz</strong> is
              not liable for delays or failures caused by events outside its
              control, including natural disasters, pandemics, or government
              restrictions.
            </li>
            <li>
              <strong>Compliance Clause:</strong> Users are solely responsible
              for ensuring compliance with all local laws, regulations, and
              permits related to their activities on the platform.
            </li>
            <li>
              <strong>Arbitration Clause:</strong> Any disputes, claims, or
              controversies arising out of or relating to these terms or the use
              of the platform shall be resolved by binding arbitration in
              accordance with the rules of <strong>Airkitchenz AI</strong>, held
              in Delaware or Los Angeles, California, as applicable.
            </li>
            <li>
              <strong>Contact Us:</strong> For questions about these terms,
              please contact us at{" "}
              <a
                href="mailto:info@airkitchenz.com"
                className="TOU-contact-email"
              >
                info@airkitchenz.com
              </a>
              .
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
