import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {

  return (
    <div className="privacy-main-container">
      <Navbar />
      <div className="privacy-container">
        <h2>AirKitchenz AI Privacy Policy</h2>
        <p className="privacy-date">
          Effective Date: <strong>November 20, 2024</strong>
        </p>
        <div className="privacy-content">
          <p>
            AirKitchenz ("we," "our," or "us") values your privacy and is
            committed to protecting your personal information. This Privacy
            Policy explains how we collect, use, and share your information.
          </p>

          <h3>1. Information We Collect</h3>
          <p>
            <strong>Personal Information:</strong> When you register, rent a
            kitchen, or list a kitchen, we may collect your name, email, phone
            number, payment information, and location.
          </p>
          <p>
            <strong>Usage Data:</strong> We collect information about your
            interactions with the platform, such as pages visited, bookings
            made, and messages sent.
          </p>
          <p>
            <strong>Device Information:</strong> We collect data about the
            device and browser you use to access our platform.
          </p>

          <h3>2. How We Use Your Information</h3>
          <p>
            To provide and improve our services, process transactions, manage
            bookings, communicate with you about your account, for analytics and
            marketing, and to comply with legal obligations and protect our
            rights.
          </p>

          <h3>3. Information Sharing</h3>
          <p>
            We do not sell your information. We may share your data with service
            providers, as required by law, to protect AirKitchenz and others, or
            in connection with a business transfer.
          </p>

          <h3>4. Your Rights</h3>
          <p>
            You have the right to access, correct, or delete your personal
            information. You may also opt out of marketing communications.
          </p>

          <h3>5. Data Security</h3>
          <p>
            We implement industry-standard measures to protect your data.
            However, no online platform is entirely secure.
          </p>

          <h3>6. Payment Disclaimer</h3>
          <p>
            AirKitchenz does not store or process payment information directly.
            Transactions are handled by third-party providers.
          </p>

          <h3>7. Force Majeure</h3>
          <p>
            AirKitchenz is not liable for delays or failures caused by events
            outside its control, such as natural disasters, pandemics, or
            government restrictions.
          </p>

          <h3>8. Changes to This Policy</h3>
          <p>
            We may update this policy periodically. We will notify you of any
            significant changes.
          </p>

          <h3>9. Contact Us</h3>
          <p>
            If you have questions about this policy, please contact us at
            <a href="mailto:info@airkitchenz.com"> info@airkitchenz.com</a>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
