import React from "react";
import { Link } from "react-router-dom";
import "./KitchenCard.css";
import img from "../../assets/images/image.png";

const KitchenCard = ({ kitchen, distance }) => {
  const { Title, City, DayRate, HourlyRate, BookingType, Images, KitchenID } =
    kitchen;

  return (
    <Link to={`/kitchen/${KitchenID}`} className="kitchen-card-link">
      <div className="kitchen-card">
        <div className="image-container">
          <img
            src={Images && Images.length > 0 ? Images[0] : img}
            alt={Title}
            className="browse-kitchen-image"
            loading="lazy"
          />
        </div>
        <div className="kitchen-info">
          <ul>
            <li className="kitchen-title">{Title}</li>
            <li className="kitchen-city">{City}</li>
            <li className="kitchen-rate">
              {BookingType === "hour"
                ? `$${HourlyRate} per hour`
                : `$${DayRate} per day`}
            </li>
            {/* Display distance if available */}
            {distance && (
              <li className="kitchen-distance">{distance} miles away</li>
            )}
          </ul>
        </div>
      </div>
    </Link>
  );
};

export default KitchenCard;
