import React, { useState, useEffect } from "react";
import { useBooking } from "../../context/BookingContext";
import { Link } from "react-router-dom";
import "./MyReservations.css";

const MyReservations = () => {
  const { fetchOwnerReservations, user } = useBooking();
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const ownerReservations = await fetchOwnerReservations();
        setReservations(ownerReservations.reverse());
      } catch (error) {
        console.error("Error fetching reservations:", error);
      }
    };
    fetchReservations();
  }, [fetchOwnerReservations]);

  if (!user) {
    return (
      <p className="loading">
        Please log in or sign up to access your reservations.
      </p>
    );
  }
  if (!reservations || reservations.length === 0) {
    return (
      <p className="loading">You currently do not have any reservations.</p>
    );
  }

  const formatTime = (time) => {
    const hours = String(Math.floor(time)).padStart(2, "0");
    return `${hours}:00`;
  };

  return (
    <div className="my-reservations-container">
      <h1>MY RESERVATIONS</h1>
      <ul className="reservations-list">
        {reservations.map((reservation) => (
          <li key={reservation.BookingID} className="reservation-item">
            <div className="reservation-details">
              <h3>
                <Link to={`/kitchen/${reservation.KitchenID}`}>
                  {reservation.KitchenTitle || "Kitchen"}
                </Link>
              </h3>
              <p>
                Check-in: {reservation.CheckinDate} Start Time:{" "}
                {formatTime(reservation.StartTime)}
              </p>
              <p>
                Check-out: {reservation.CheckoutDate} End Time:{" "}
                {formatTime(reservation.EndTime)}
              </p>
              <p className="status">
                Status: {reservation.Status || "Pending"}
              </p>
            </div>
            <div className="reservation-price">
              <p>Total Amount: ${reservation.TotalAmount}</p>
              <p>Booking Type: {reservation.BookingType}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyReservations;
