import React, { useState, useEffect, useContext } from "react";
import "./Settings.css";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import config from "../../config";

const Settings = () => {
  const { user, token, fetchUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("Fetching...");

  // Fetch the latest user details on component load
  useEffect(() => {
    const fetchLatestStatus = async () => {
      if (user?.userID) {
        try {
          await fetchUserProfile(user.userID);
          console.log("USER DATA:", user);
          setVerificationStatus(user.idVerificationStatus || "N/A");
        } catch (error) {
          console.error("Error fetching user details:", error);
          setVerificationStatus("Unable to fetch status");
        }
      }
    };

    fetchLatestStatus();
  }, []);

  const handleStartVerification = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/stripe/create-verification-session`,
        { userID: user.userID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { verificationSessionURL } = response.data;
      window.location.href = verificationSessionURL;
    } catch (error) {
      console.error("Error initiating verification:", error);
      setVerificationStatus(
        error.response?.data?.message || "Failed to initiate verification."
      );
      setLoading(false);
    }
  };

  return (
    <div className="settings-container">
      <h2>Status :</h2>
      <p>{verificationStatus}</p>
      <h2>Upload and Verify Your ID</h2>
      <button onClick={handleStartVerification} disabled={loading}>
        {loading ? "Redirecting to Verification..." : "Start Verification"}
      </button>
    </div>
  );
};

export default Settings;
