import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useBooking } from "../../context/BookingContext";
import "./Calendar.css";
import Modal from "react-modal";

const localizer = momentLocalizer(moment);

const customModalStyles = {
  content: {
    inset: "50% auto auto 50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "10px",
    maxWidth: "500px",
    width: "90%",
    boxSizing: "border-box",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1000,
  },
};

Modal.setAppElement("#root");

const Calendar = () => {
  const { fetchUserBookings, fetchOwnerReservations } = useBooking();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const userBookings = await fetchUserBookings();
        const ownerReservations = await fetchOwnerReservations();

        const formattedEvents = [...userBookings, ...ownerReservations].map(
          (item) => ({
            id: item.BookingID,
            title: `${item.KitchenTitle} (${item.BookingType})`,
            start: new Date(
              `${item.CheckinDate}T${String(item.StartTime).padStart(
                2,
                "0"
              )}:00:00`
            ),
            end: new Date(
              `${item.CheckoutDate}T${String(item.EndTime).padStart(
                2,
                "0"
              )}:00:00`
            ),
            resource: item,
          })
        );

        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error loading events:", error);
      }
    };

    loadEvents();
  }, [fetchUserBookings, fetchOwnerReservations]);

  const EventComponent = ({ event }) => {
    const { BookingType, StartTime, EndTime } = event.resource;
    const timeDisplay =
      BookingType === "hour"
        ? `${String(StartTime).padStart(2, "0")}:00 - ${String(
            EndTime
          ).padStart(2, "0")}:00`
        : "";
    return (
      <div className="custom-event">
        <span>{event.title}</span>
        {timeDisplay && <span className="event-time">{timeDisplay}</span>}
      </div>
    );
  };


  
  // if (isMobile) {
  //   return (
  //     <div className="mobile-message">
  //       <p>Calendar view is only available on desktop devices.</p>
  //     </div>
  //   );
  // }

  return (
    <div className="calendar-container">
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "80vh", width: "100%", padding: "10px" }}
        eventPropGetter={(event) => {
          const backgroundColor =
            event.resource.BookingType === "hour" ? "#ff7f50" : "#6aa84f";
          return {
            style: {
              backgroundColor,
              color: "white",
              borderRadius: "4px",
              padding: "2px",
            },
          };
        }}
        components={{
          event: EventComponent,
        }}
        onSelectEvent={(event) => setSelectedEvent(event)}
        views={["month", "week", "day", "agenda"]}
        defaultView="month"
        step={60}
        timeslots={1}
      />

      {selectedEvent && (
        <Modal
          isOpen={!!selectedEvent}
          onRequestClose={() => setSelectedEvent(null)}
          style={customModalStyles}
          contentLabel="Event Details"
        >
          <h2>{selectedEvent.title}</h2>
          <button
            onClick={() => setSelectedEvent(null)}
            className="close-button"
          >
            &times;
          </button>
          <div className="modal-content">
            <p>
              <strong>Booking ID:</strong> {selectedEvent.resource.BookingID}
            </p>
            <p>
              <strong>Kitchen Title:</strong>{" "}
              {selectedEvent.resource.KitchenTitle}
            </p>
            <p>
              <strong>Booking Type:</strong>{" "}
              {selectedEvent.resource.BookingType}
            </p>
            <p>
              <strong>Check-in:</strong>{" "}
              {moment(selectedEvent.start).format("LLLL")}
            </p>
            <p>
              <strong>Check-out:</strong>{" "}
              {moment(selectedEvent.end).format("LLLL")}
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Calendar;
