import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import axios from "axios";
import config from "../../config";
import "./AIBuilder.css";

const AiBuilder = () => {
  const [prompt, setPrompt] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/generate-menu`,
        { prompt }
      );
      setResult(response.data.result);
    } catch (error) {
      console.error("Error generating menu:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      const userId = localStorage.getItem("userID");
      const response = await axios.post(`${config.apiBaseUrl}/api/download-pdf`, {
        content: result,
        userId,
      });
      const pdfUrl = response.data.pdfUrl;
      window.open(pdfUrl, "_blank"); // Open PDF in a new tab
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div className="ai-builder-container">
      <Navbar />
      <div className="ai-builder-main-content">
        <h1 className="page-title">AI-Powered Kitchen Business Features</h1>
        <div className="features-vertical">
          {/* AI Menu Builder Feature */}
          <div className="aifeature">
            <h2>AI Menu Builder</h2>
            <div className="textarea-container">
              <textarea
                value={prompt}
                onChange={handlePromptChange}
                placeholder="Enter your ideas here..."
                className="prompt-box"
                rows="4"
              />
              {/* Uncomment the following lines if you want to add a clear button */}
              {/* {prompt && (
                <button onClick={handleClearPrompt} className="clear-button">
                  ✖
                </button>
              )} */}
            </div>
            <button
              onClick={handleGenerate}
              className="generate-button"
              disabled={loading}
            >
              {loading ? "Generating..." : "Generate Menu"}
            </button>
            {loading && <p className="loading-text">Generating your menu, please wait...</p>}
            <div className="textarea-container">
              <textarea
                value={result}
                readOnly
                placeholder="AI-generated result will appear here..."
                className="result-box"
                rows="8"
              />
              {/* Uncomment the following lines if you want to add a clear button */}
              {/* {result && (
                <button onClick={handleClearResult} className="clear-button">
                  ✖
                </button>
              )} */}
            </div>
            <button
              onClick={handleDownloadPDF}
              className="download-button"
              disabled={!result}
            >
              Download Menu
            </button>
          </div>

          {/* AI Powered Menu Uploader Feature */}
          <div className="aifeature coming-soon">
            <h2>AI Powered Menu Uploader</h2>
            <p>Coming Soon!</p>
          </div>

          {/* AI Kitchen Matchmaker Feature */}
          <div className="aifeature coming-soon">
            <h2>AI Kitchen Matchmaker</h2>
            <p>Coming Soon!</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AiBuilder;
