import React, { useState, useEffect, useRef } from "react";
import { useKitchen } from "../../context/KitchenContext";
import "./KitchenPrice.css";
import { FaTimes } from "react-icons/fa";

const KitchenPrice = ({
  nextStep,
  prevStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const { updateKitchen, updateKitchenEditMode } = useKitchen();
  const [loading, setLoading] = useState(false);

  // Use local state variables, initialized from kitchenData
  const [bookingType, setBookingType] = useState(
    kitchenData.bookingType || "hour"
  );
  const [hourlyRate, setHourlyRate] = useState(kitchenData.hourlyRate || "");
  const [dayRate, setDayRate] = useState(kitchenData.dayRate || "");
  const [cleaningFee, setCleaningFee] = useState(kitchenData.cleaningFee || "");
  const [minBooking, setMinBooking] = useState(kitchenData.minBooking || "");
  const [securityDeposit, setSecurityDeposit] = useState(
    kitchenData.securityDeposit || ""
  );
  const [rateEntries, setRateEntries] = useState(kitchenData.rateEntries || []);

  // Initialize state only once to prevent resets
  const isInitialized = useRef(false);
  useEffect(() => {
    if (!isInitialized.current && editMode && kitchenData) {
      console.log(kitchenData);
      setBookingType(kitchenData.bookingType || "hour");
      setHourlyRate(kitchenData.hourlyRate || "");
      setDayRate(kitchenData.dayRate || "");
      setCleaningFee(kitchenData.cleaningFee || "");
      setMinBooking(kitchenData.minBooking || "");
      setSecurityDeposit(kitchenData.securityDeposit || "");
      setRateEntries(kitchenData.rateEntries || []);
      isInitialized.current = true;
    }
  }, [editMode, kitchenData]);

  const isOverlapping = (newEntry, excludeIndex = null) => {
    const newStart = parseInt(newEntry.start, 10);
    const newEnd = parseInt(newEntry.end, 10);

    if (
      !newEntry.day ||
      isNaN(newStart) ||
      isNaN(newEnd) ||
      newStart >= newEnd
    ) {
      return true;
    }

    return rateEntries.some((entry, index) => {
      if (entry.day !== newEntry.day) return false;
      if (index === excludeIndex) return false;

      const existingStart = parseInt(entry.start, 10);
      const existingEnd = parseInt(entry.end, 10);

      return newStart < existingEnd && newEnd > existingStart;
    });
  };

  const handleAddRateEntry = () => {
    const newEntry = { day: "", start: "", end: "", price: "" };
    setRateEntries([...rateEntries, newEntry]);
  };

  const handleRemoveRateEntry = (index) => {
    setRateEntries(rateEntries.filter((_, i) => i !== index));
  };

  const handleRateChange = (index, field, value) => {
    const newEntries = [...rateEntries];
    newEntries[index][field] = value;

    const entry = newEntries[index];
    if (entry.day && entry.start !== "" && entry.end !== "") {
      if (isOverlapping(entry, index)) {
        alert(
          "The selected time range overlaps with an existing entry or is invalid. Please choose a non-overlapping time."
        );
        return;
      }
    }

    setRateEntries(newEntries);
  };

  const handleNext = async (e) => {
    e.preventDefault();

    const pricingData = {
      bookingType,
      hourlyRate,
      dayRate,
      cleaningFee,
      minBooking,
      securityDeposit,
      rateEntries,
    };

    // Merge the new pricing data with the existing kitchenData
    const updatedKitchenData = { ...kitchenData, ...pricingData };

    setLoading(true);
    try {
      if (editMode) {
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        await updateKitchen(updatedKitchenData);
      }
      // Update central kitchenData
      updateKitchenData(pricingData);

      nextStep();
    } catch (error) {
      console.error("Failed to update pricing:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleNext} className="kitchen-price">
      <h2 className="form-title">
        {editMode ? "Edit Pricing Details" : "Pricing Details"}
      </h2>

      <div className="price-group">
        <div className="price-form-group">
          <label>Booking Type</label>
          <select
            value={bookingType}
            onChange={(e) => {
              setBookingType(e.target.value);
            }}
          >
            <option value="hour">Per Hour</option>
            <option value="day">Per Day</option>
          </select>
        </div>

        {bookingType === "hour" ? (
          <div className="price-form-group">
            <label>Hourly Rate</label>
            <input
              type="number"
              placeholder="Enter hourly rate"
              value={hourlyRate}
              onChange={(e) => {
                setHourlyRate(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className="price-form-group">
            <label>Day Rate</label>
            <input
              type="number"
              placeholder="Enter day rate"
              value={dayRate}
              onChange={(e) => {
                setDayRate(e.target.value);
              }}
            />
          </div>
        )}

        <div className="price-form-group">
          {bookingType === "hour" ? (
            <>
              <label>Minimum Booking Hours</label>
              <input
                type="number"
                placeholder="Enter minimum hours"
                value={minBooking}
                onChange={(e) => {
                  setMinBooking(e.target.value);
                }}
              />
              <small className="field-note">
                AirKitchenz recommends a minimum of 3 hours.
              </small>
            </>
          ) : (
            <>
              <label>Minimum Booking Days</label>
              <input
                type="number"
                placeholder="Enter minimum days"
                value={minBooking}
                onChange={(e) => {
                  setMinBooking(e.target.value);
                }}
              />
              <small className="field-note">
                AirKitchenz recommends minimum of 1 day.
              </small>
            </>
          )}
        </div>
      </div>

      <h2 className="price-title">Fees</h2>
      <div className="form-row">
        <div className="price-form-group">
          <label>Cleaning Fees ($)</label>
          <input
            type="number"
            placeholder="Enter cleaning fee"
            value={cleaningFee}
            onChange={(e) => {
              setCleaningFee(e.target.value);
            }}
          />
        </div>

        <div className="price-form-group">
          <label>Security Deposit ($)</label>
          <input
            type="number"
            placeholder="Enter security deposit"
            value={securityDeposit}
            onChange={(e) => {
              setSecurityDeposit(e.target.value);
            }}
          />
          <small className="field-note">
            AirKitchenz recommends $300 a day, to be adjusted in case of
            bookings longer than 2 days. Be fair to your Renters and build a
            community. Deposits will be held by AirKitchenz. If a complaint
            isn’t filed by Kitchen Owners within 24 hours, deposits will be
            returned to Renters.
          </small>
        </div>
      </div>

      {bookingType === "hour" ? (
        <h2 className="price-title">Set Availability for kitchen</h2>
      ) : (
        <h2 className="price-title">Set Rates / Day</h2>
      )}
      <div className="rate-entries">
        {rateEntries.map((entry, index) => (
          <div className="rate-entry" key={index}>
            <select
              value={entry.day}
              onChange={(e) => handleRateChange(index, "day", e.target.value)}
            >
              <option value="">Select Day</option>
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
              <option value="sunday">Sunday</option>
            </select>

            {bookingType === "hour" && (
              <>
                <select
                  value={entry.start}
                  onChange={(e) =>
                    handleRateChange(index, "start", e.target.value)
                  }
                >
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i.toString().padStart(2, "0")}:00
                    </option>
                  ))}
                </select>

                <select
                  value={entry.end}
                  onChange={(e) =>
                    handleRateChange(index, "end", e.target.value)
                  }
                >
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>
                      {i.toString().padStart(2, "0")}:00
                    </option>
                  ))}
                </select>
              </>
            )}

            <input
              type="number"
              placeholder="Price"
              value={entry.price}
              onChange={(e) => handleRateChange(index, "price", e.target.value)}
            />

            <FaTimes
              className="remove-entry-icon"
              onClick={() => handleRemoveRateEntry(index)}
            />
          </div>
        ))}
        <div className="entry-btn-container">
          <button
            type="button"
            className="add-entry-btn"
            onClick={handleAddRateEntry}
          >
            Add Entry
          </button>
        </div>
      </div>
      <div className="navigation-buttons">
        <button type="button" onClick={prevStep} className="prev-btn">
          Previous
        </button>
        <button type="submit" className="next-btn" disabled={loading}>
          {loading ? "Saving..." : editMode ? "Update" : "Next"}
        </button>
      </div>
    </form>
  );
};

export default KitchenPrice;
