import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useKitchen } from "../../context/KitchenContext";
import { useBooking } from "../../context/BookingContext";
import Slider from "react-slick";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaTimes,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import "./Kitchen.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from "../../assets/images/image.png";

const Kitchen = () => {
  const { kitchenID } = useParams();
  const [kitchen, setKitchen] = useState(null);
  const [checkinDate, setCheckinDate] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showRenterTermsModal, setshowRenterTermsModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchKitchenById, user, loadingUser } = useKitchen();
  const { initiateStripePayment, checkKitchenAvailability } = useBooking();
  const [agreed, setAgreed] = useState(false);
  const link = window.location.href;
  const isHourly = kitchen?.BookingType === "hour";

  // Availability checks
  const [availabilityChecked, setAvailabilityChecked] = useState(false);
  const [availabilityError, setAvailabilityError] = useState("");
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);

  // Get today's date and current time
  const now = new Date();
  const todayDate = now.toLocaleDateString("en-CA"); // "en-CA" for YYYY-MM-DD format
  const currentHour = now.getHours();

  useEffect(() => {
    // if (loadingUser) return;
    const fetchKitchen = async () => {
      try {
        const kitchenData = await fetchKitchenById(kitchenID);
        setKitchen(kitchenData);
      } catch (error) {
        console.error("Error fetching kitchen:", error);
      }
    };
    fetchKitchen();
  }, [kitchenID, fetchKitchenById, loadingUser, navigate, user]);

  const handleBooking = async () => {
    if (user == null) {
      window.location.href = "/login";
      return;
    }

    if (!agreed) {
      alert("Please agree to the Terms and Conditions before booking.");
      return;
    }

    if (
      !checkinDate ||
      !checkoutDate ||
      (isHourly && (startTime === "" || endTime === ""))
    ) {
      alert("Please fill in all booking details.");
      return;
    }

    // Reset previous errors
    setAvailabilityError("");
    setAvailabilityChecked(false);

    try {
      setIsCheckingAvailability(true);

      // Perform availability check
      console.log("KITCHEN ID in book now :", kitchenID);
      const availabilityResponse = await checkKitchenAvailability(
        kitchenID,
        checkinDate,
        checkoutDate,
        startTime,
        endTime
      );

      setIsCheckingAvailability(false);

      if (!availabilityResponse.available) {
        setAvailabilityError(
          availabilityResponse.message ||
            "The kitchen is not available for the selected dates and times."
        );
        return;
      }

      setAvailabilityChecked(true);

      // Proceed with further validations
      const checkin = isHourly
        ? new Date(`${checkinDate}T${startTime.padStart(2, "0")}:00`)
        : new Date(checkinDate);
      const checkout = isHourly
        ? new Date(`${checkoutDate}T${endTime.padStart(2, "0")}:00`)
        : new Date(checkoutDate);

      const now = new Date();

      if (isHourly) {
        if (checkout <= checkin) {
          alert("Check-out time must be after check-in time.");
          return;
        }
      } else {
        if (checkout <= checkin) {
          alert("Check-out date must be after check-in date.");
          return;
        }
      }

      const bookingDetails = {
        kitchenID,
        kitchenOwnerID: kitchen.UserID,
        userID: user.userID,
        kitchenTitle: kitchen.Title,
        bookingType: kitchen.BookingType,
        totalAmount:
          parseFloat(totalAmount.toFixed(2)) +
          (parseFloat(kitchen.SecurityDeposit) || 0),
        checkinDate: checkinDate,
        checkoutDate: checkoutDate,
        startTime: startTime,
        endTime: endTime,
        totalHours: totalHours,
        totalDays: totalDays,
      };

      await initiateStripePayment(bookingDetails);
    } catch (error) {
      setIsCheckingAvailability(false);
      if (error.message) {
        setAvailabilityError(error.message);
      } else if (error.error) {
        setAvailabilityError(error.error);
      } else {
        setAvailabilityError("An unexpected error occurred. Please try again.");
      }
    }
  };

  const calculateTotalAmount = () => {
    try {
      if (isHourly) {
        if (
          !checkinDate ||
          !checkoutDate ||
          startTime === "" ||
          endTime === ""
        ) {
          setTotalHours(0);
          setTotalAmount(0);
          return;
        }

        const checkin = new Date(
          `${checkinDate}T${startTime.padStart(2, "0")}:00`
        );
        const checkout = new Date(
          `${checkoutDate}T${endTime.padStart(2, "0")}:00`
        );

        const hoursDifference = (checkout - checkin) / (1000 * 60 * 60);
        if (hoursDifference <= 0) {
          setTotalHours(0);
          setTotalAmount(0);
          return;
        }
        setTotalHours(hoursDifference);
        setTotalAmount(hoursDifference * (parseFloat(kitchen.HourlyRate) || 0));
      } else {
        if (!checkinDate || !checkoutDate) {
          setTotalDays(0);
          setTotalAmount(0);
          return;
        }

        const checkin = new Date(checkinDate);
        const checkout = new Date(checkoutDate);

        // Calculate the difference in days
        const timeDiff = checkout - checkin;
        const daysDifference = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        if (daysDifference <= 0) {
          setTotalDays(0);
          setTotalAmount(0);
          return;
        }
        setTotalDays(daysDifference);
        setTotalAmount(daysDifference * (parseFloat(kitchen.DayRate) || 0));
      }
    } catch (error) {
      console.error("Error calculating total amount:", error);
      setTotalAmount(0);
      if (isHourly) setTotalHours(0);
      else setTotalDays(0);
    }
  };

  const handleCheckoutChange = (e) => {
    try {
      const selectedCheckoutDate = e.target.value;
      setCheckoutDate(selectedCheckoutDate);
      // If checkout date is before checkin date, reset
      if (checkinDate && selectedCheckoutDate < checkinDate) {
        alert("Checkout date cannot be before check-in date.");
        setCheckoutDate("");
      }
      // Reset end time if necessary
      if (selectedCheckoutDate && selectedCheckoutDate !== checkinDate) {
        setEndTime("");
      }
      calculateTotalAmount();
    } catch (error) {
      console.error("Error handling checkout date change:", error);
    }
  };

  const handleCheckinChange = (e) => {
    try {
      const selectedCheckinDate = e.target.value;
      setCheckinDate(selectedCheckinDate);
      // Reset checkout date if it's before the new checkin date
      if (checkoutDate && selectedCheckinDate > checkoutDate) {
        setCheckoutDate("");
      }
      // Reset times if check-in date changes
      setStartTime("");
      setEndTime("");
      calculateTotalAmount();
    } catch (error) {
      console.error("Error handling check-in date change:", error);
    }
  };

  const handleStartTimeChange = (e) => {
    try {
      const selectedStartTime = e.target.value;
      setStartTime(selectedStartTime);
      // Reset end time if it's before the new start time
      if (endTime && parseInt(selectedStartTime) >= parseInt(endTime)) {
        setEndTime("");
      }
      calculateTotalAmount();
    } catch (error) {
      console.error("Error handling start time change:", error);
    }
  };

  const handleEndTimeChange = (e) => {
    try {
      setEndTime(e.target.value);
      calculateTotalAmount();
    } catch (error) {
      console.error("Error handling end time change:", error);
    }
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [checkinDate, checkoutDate, startTime, endTime, isHourly]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const session_id = urlParams.get("session_id");

    if (session_id) {
      setModalContent({
        type: "success",
        message: "Thank you for your payment!",
        details: `Transaction ID: ${session_id}`,
      });
      setShowBookingModal(true);
    }
  }, [location.search]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatTime = (time) => {
    if (time == null || isNaN(parseInt(time, 10))) {
      return "00:00";
    }
    const formattedTime = parseInt(time, 10);
    return `${formattedTime.toString().padStart(2, "0")}:00`;
  };
  const getMinStartHour = () => {
    if (todayDate === checkinDate) {
      return (currentHour + 1) % 24;
    }
    return 0;
  };

  const getMinEndHour = () => {
    if (checkinDate === checkoutDate) {
      return (parseInt(startTime) + 1) % 24;
    }
    return 0;
  };

  const getStartTimeOptions = () => {
    const options = [];
    const minHour = getMinStartHour();

    for (let i = minHour; i <= 23; i++) {
      options.push(
        <option key={i} value={i}>
          {i.toString().padStart(2, "0")}:00
        </option>
      );
    }

    return (
      <>
        <option value="">Select Start Time</option>
        {options}
      </>
    );
  };

  const getEndTimeOptions = () => {
    const options = [];

    if (!startTime) {
      return <option value="">Select End Time</option>;
    }

    if (checkinDate === checkoutDate) {
      const minEnd = getMinEndHour();
      for (let i = minEnd; i <= 23; i++) {
        options.push(
          <option key={i} value={i}>
            {i.toString().padStart(2, "0")}:00
          </option>
        );
      }
    } else {
      for (let i = 0; i <= 23; i++) {
        options.push(
          <option key={i} value={i}>
            {i.toString().padStart(2, "0")}:00
          </option>
        );
      }
    }

    return (
      <>
        <option value="">Select End Time</option>
        {options}
      </>
    );
  };

  const toggleBookingModal = () => setShowBookingModal(false);
  const toggleRenterTermsModal = () =>
    setshowRenterTermsModal(!showRenterTermsModal);

  const toggleDropdown = (section) => {
    setActiveDropdown(activeDropdown === section ? null : section);
  };

  if (!kitchen) {
    return <div>Loading...</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const sliderSettings =
    kitchen.Images && kitchen.Images.length > 1
      ? {
          ...settings,
          infinite: true,
        }
      : {
          ...settings,
          infinite: false,
          slidesToShow: 1,
        };

  const isNewAmenitiesFormat =
    kitchen.Amenities &&
    typeof kitchen.Amenities === "object" &&
    !Array.isArray(kitchen.Amenities);

  return (
    <>
      <Navbar />
      <div className="kitchen-page">
        <div className="kitchen-image-slider">
          <Slider {...sliderSettings}>
            {kitchen.Images && kitchen.Images.length > 0 ? (
              kitchen.Images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Kitchen image ${index + 1}`}
                    className="kitchen-slider-image"
                    onError={(e) => {
                      e.target.src = img;
                    }}
                  />
                </div>
              ))
            ) : (
              <div>
                <img
                  src={img}
                  alt="Default Kitchen"
                  className="kitchen-slider-image"
                />
              </div>
            )}
          </Slider>
        </div>

        <div className="kitchen-content">
          <div className="kitchen-details-container">
            <div className="kitchen-header">
              <h1>{kitchen.Title || "Untitled Kitchen"}</h1>
              {/* <p><strong>Description:</strong> {kitchen.Description || "Not Provided"}</p> */}
              <p>
                <strong>Size:</strong>{" "}
                {capitalizeFirstLetter(kitchen.Size) || "N/A"}
              </p>
              <p>
                <strong>Type:</strong>{" "}
                {capitalizeFirstLetter(kitchen.Category) || "N/A"}
              </p>
            </div>

            {/* Existing Dropdown Sections */}
            <div
              className={`dropdown-section ${
                activeDropdown === "price" ? "active" : ""
              }`}
            >
              <div
                className="dropdown-header"
                onClick={() => toggleDropdown("price")}
              >
                Price Details
              </div>
              <div className="dropdown-content">
                <p>
                  {isHourly
                    ? `Hourly Rate: $${kitchen.HourlyRate || "N/A"}`
                    : `Daily Rate: $${kitchen.DayRate || "N/A"}`}
                </p>
                <p>
                  Minimum Booking:{" "}
                  {isHourly
                    ? `${kitchen.MinBooking || "N/A"} hours`
                    : `${kitchen.MinBooking || "N/A"} day(s)`}
                </p>
                <p>Security Deposit: ${kitchen.SecurityDeposit || "N/A"}</p>
                <p>Cleaning Fee: ${kitchen.CleaningFee || "N/A"}</p>
              </div>
            </div>

            <div
              className={`dropdown-section ${
                activeDropdown === "address" ? "active" : ""
              }`}
            >
              <div
                className="dropdown-header"
                onClick={() => toggleDropdown("address")}
              >
                Kitchen Address
              </div>
              <div className="dropdown-content">
                <p>Exact location provided after booking confirmation.</p>
                <p>City: {kitchen.City || "N/A"}</p>
                <p>Neighborhood: {kitchen.Neighborhood || "N/A"}</p>
              </div>
            </div>

            <div
              className={`dropdown-section ${
                activeDropdown === "amenities" ? "active" : ""
              }`}
            >
              <div
                className="dropdown-header"
                onClick={() => toggleDropdown("amenities")}
              >
                Amenities
              </div>
              <div className="dropdown-content">
                {kitchen.Amenities &&
                Object.keys(kitchen.Amenities).length > 0 ? (
                  isNewAmenitiesFormat ? (
                    Object.entries(kitchen.Amenities).map(
                      ([category, amenitiesList], index) => (
                        <div key={index}>
                          <h4>{capitalizeFirstLetter(category)}</h4>
                          <ul>
                            {amenitiesList.map((amenity, idx) => (
                              <li key={idx}>{amenity}</li>
                            ))}
                          </ul>
                        </div>
                      )
                    )
                  ) : (
                    <p>No amenities available</p>
                  )
                ) : (
                  <p>No amenities available</p>
                )}
              </div>
            </div>

            {/* New Rates and Availability Dropdown */}
            <div
              className={`dropdown-section ${
                activeDropdown === "rates" ? "active" : ""
              }`}
            >
              <div
                className="dropdown-header"
                onClick={() => toggleDropdown("rates")}
              >
                Rates and Availability
              </div>
              <div className="dropdown-content">
                {kitchen.RateEntries && kitchen.RateEntries.length > 0 ? (
                  <table className="rates-table">
                    <thead>
                      <tr>
                        <th>Day</th>
                        {isHourly && <th>Start Time</th>}
                        {isHourly && <th>End Time</th>}
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kitchen.RateEntries.map((entry, index) => (
                        <tr key={index}>
                          <td>{capitalizeFirstLetter(entry.day)}</td>
                          {isHourly && (
                            <>
                              <td>{formatTime(entry.start)}</td>
                              <td>{formatTime(entry.end)}</td>
                            </>
                          )}
                          <td>${parseFloat(entry.price).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No rate entries available.</p>
                )}
              </div>
            </div>
          </div>

          <div className="kitchen-booking-section">
            <h3>
              ${isHourly ? kitchen.HourlyRate : kitchen.DayRate} per{" "}
              {isHourly ? "hour" : "day"}
            </h3>

            <div className="booking-form">
              <label htmlFor="checkin">Check-In Date</label>
              <input
                type="date"
                id="checkin"
                value={checkinDate}
                onChange={handleCheckinChange}
                min={todayDate} // Allow selecting today and future dates
              />

              <label htmlFor="checkout">Check-Out Date</label>
              <input
                type="date"
                id="checkout"
                value={checkoutDate}
                onChange={handleCheckoutChange}
                min={checkinDate || todayDate} // Ensure checkout is not before checkin
              />
              <div className="time-selection">
                <div className="time-field">
                  <label htmlFor="startTime">Start Time</label>
                  <select
                    id="startTime"
                    value={startTime}
                    onChange={handleStartTimeChange}
                  >
                    {getStartTimeOptions()}
                  </select>
                </div>
                <div className="time-field">
                  <label htmlFor="endTime">End Time</label>
                  <select
                    id="endTime"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    disabled={!startTime}
                  >
                    {getEndTimeOptions()}
                  </select>
                </div>
              </div>

              {/* Display Availability Errors */}
              {availabilityError && (
                <div className="error-message">
                  <FaTimesCircle className="error-icon" />
                  <span>{availabilityError}</span>
                </div>
              )}

              {/* Loading Indicator for Availability Check */}
              {isCheckingAvailability && (
                <div className="loading-message">
                  <span>Checking availability...</span>
                </div>
              )}

              {/* Conditional Display of Cost Breakdown with Border */}
              {checkinDate && checkoutDate && (
                <div
                  className="cost-breakdown"
                  style={{
                    border: "1px solid #ddd",
                    padding: "10px",
                    marginTop: "15px",
                  }}
                >
                  <table>
                    <tbody>
                      {isHourly ? (
                        // If hourly, show Hourly Rate breakdown
                        <tr>
                          <td>{`$${kitchen.HourlyRate} x ${totalHours} hours`}</td>
                          <td>${totalAmount.toFixed(2)}</td>
                        </tr>
                      ) : (
                        // If daily, show Day Rate breakdown
                        <tr>
                          <td>{`$${kitchen.DayRate} x ${totalDays} day(s)`}</td>
                          <td>${totalAmount.toFixed(2)}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Security Deposit (*refundable)</td>
                        <td>${kitchen.SecurityDeposit || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>TOTAL</strong>
                        </td>
                        <td>
                          <strong>
                            $
                            {(
                              totalAmount +
                              (parseFloat(kitchen.SecurityDeposit) || 0)
                            ).toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <div className="renter-terms-container">
                <input
                  type="checkbox"
                  id="terms"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                />
                <label htmlFor="terms" onClick={toggleRenterTermsModal}>
                  I agree to the{" "}
                  <span className="terms-link">Terms and Conditions</span>
                </label>
              </div>

              <button
                className="booking-btn"
                onClick={handleBooking}
                disabled={isCheckingAvailability}
              >
                {isCheckingAvailability ? "Checking..." : "Book Now"}
              </button>
            </div>

            <div className="social-links">
              <span>Share</span>
              <a
                href={`https://www.instagram.com/direct/new/?text=${encodeURIComponent(
                  "Check out this kitchen on AirKitchenz: " + link
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Instagram DM"
              >
                <FaInstagram />
              </a>

              <a
                href={`https://m.me/?link=${encodeURIComponent(link)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Facebook Messenger DM"
              >
                <FaFacebook />
              </a>

              <a
                href={`https://www.linkedin.com/messaging/compose?body=${encodeURIComponent(
                  "Check out this kitchen on AirKitchenz: " + link
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on LinkedIn DM"
              >
                <FaLinkedin />
              </a>

              <a
                href={`https://twitter.com/messages/compose?recipient_id=&text=${encodeURIComponent(
                  "Check out this kitchen on AirKitchenz: " + link
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share via Twitter DM"
              >
                <FaTwitter />
              </a>

              <a
                href={`mailto:?subject=Check out this kitchen&body=${encodeURIComponent(
                  "Check out this kitchen on AirKitchenz: " + link
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share via Email"
              >
                <FaEnvelope />
              </a>
            </div>
          </div>
        </div>

        {/* Booking Success/Error Modal */}
        {showBookingModal && (
          <div
            className="kitchen-page-modal-overlay"
            onClick={toggleBookingModal}
          >
            <div
              className="kitchen-page-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="kitchen-page-modal-close"
                onClick={toggleBookingModal}
                aria-label="Close Modal"
              >
                &times;
              </button>
              {modalContent.type === "success" ? (
                <FaCheckCircle className="kitchen-page-modal-icon success" />
              ) : (
                <FaTimesCircle className="kitchen-page-modal-icon error" />
              )}
              <h2>{modalContent.message}</h2>
              <p>{modalContent.details}</p>
              {modalContent.type === "success" && (
                <a
                  href="/profile/my-bookings"
                  className="kitchen-page-modal-link"
                >
                  View My Bookings
                </a>
              )}
            </div>
          </div>
        )}

        {/* Terms and Conditions Modal */}
        {showRenterTermsModal && (
          <div
            className="kitchen-page-modal-overlay"
            onClick={toggleRenterTermsModal}
          >
            <div
              className="kitchen-page-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <FaTimes
                className="kitchen-page-modal-close"
                onClick={toggleRenterTermsModal}
                aria-label="Close Terms Modal"
              />
              <div className="kitchen-page-modal-body">
                <h2>Kitchen Renter Terms and Conditions</h2>

                <p>
                  This Rental Agreement and Disclaimer (“Agreement”) is entered
                  into between the renter (“Renter”) and AirKitchenz, governing
                  the use of kitchens rented through the AirKitchenz platform.
                  By checking the box below, the Renter acknowledges and agrees
                  to comply with all terms and conditions herein.
                </p>

                <h3>1. Insurance Requirements</h3>
                <p>
                  The Renter agrees to maintain adequate insurance coverage for
                  the duration of the rental period, including but not limited
                  to liability insurance and any other insurance deemed
                  necessary by AirKitchenz. The Renter must provide proof of
                  insurance and identification (driver’s license or passport) to
                  cover any liability or damage occurring during the rental
                  period.
                </p>

                <h3>2. Documentation Requirements</h3>
                <p>
                  The Renter is required to upload the following documentation
                  to the Platform prior to the commencement of the rental
                  period:
                </p>
                <ul>
                  <li>Proof of identity (driver’s license or passport)</li>
                  <li>Liability insurance</li>
                  <li>Food handler certification</li>
                </ul>
                <p>
                  Failure to upload the required documentation may result in
                  cancellation of the booking and forfeiture of any deposits
                  paid.
                </p>

                <h3>3. Deposit and Damage Protection</h3>
                <p>
                  The Platform will collect a security deposit from the Renter
                  at the time of booking. This deposit will be held by the
                  Platform for 66 hours after the Kitchen booking is finalized.
                  The Renter acknowledges that they are solely responsible for
                  any damage to the rented kitchen, missing items, or any other
                  issues that occur during the rental period.
                </p>
                <p>
                  The Kitchen Owner has 66 hours after the booking period ends
                  to report any issues or damages to the Kitchen or equipment.
                  If no issues or damages are reported within this timeframe,
                  the Platform will return the security deposit to the Renter.
                  If any damage, missing items, or other costs exceed the
                  deposit, AirKitchenz is authorized to charge the Renter’s
                  credit card for the additional amount necessary to cover the
                  expenses incurred.
                </p>

                <h3>4. Clean-Up Responsibility and Documentation</h3>
                <p>
                  The Renter agrees to leave the rented kitchen in the same
                  condition as it was found, including but not limited to
                  cleaning up after use, disposing of waste properly, and
                  returning any borrowed items to their original location.
                </p>
                <p>
                  To ensure the condition of the kitchen is documented, the
                  Renter is encouraged to take photographs of the kitchen upon
                  arrival, clearly capturing the initial state and condition of
                  the kitchen and its equipment. Upon completing the use of the
                  kitchen, the Renter should take additional photographs to
                  document the state in which the kitchen and all equipment were
                  left.
                </p>
                <p>
                  Any disputes regarding the condition of the kitchen or
                  equipment must be substantiated with clear photographic
                  evidence taken before and after the rental period. AirKitchenz
                  reserves the right to make a final decision based on the
                  evidence provided.
                </p>

                <h3>5. Liability and Indemnification</h3>
                <p>
                  The Renter agrees that AirKitchenz shall not be held liable
                  for any damage, missing items, accidents, or issues that occur
                  during the rental period. The Renter further agrees to
                  indemnify and hold AirKitchenz harmless from any claims,
                  damages, or liabilities arising out of the Renter’s use of the
                  rented kitchen.
                </p>
                <p>
                  AirKitchenz acts solely as a facilitator and is not
                  responsible for any agreements, actions, or negligence on the
                  part of the kitchen owner or the Renter. All liability rests
                  with the respective parties involved in the rental agreement.
                </p>

                <h3>6. Platform Facilitation and Transaction Requirements</h3>
                <p>
                  The Renter acknowledges and agrees that AirKitchenz does not
                  own any of the kitchen locations listed on its platform.
                  AirKitchenz serves solely as a facilitator of transactions
                  between the Renter and kitchen owners.
                </p>
                <p>
                  All transactions, payments, and communications must be
                  conducted exclusively through the AirKitchenz platform. No
                  exchange of personal contact information, including phone
                  numbers or email addresses, is allowed between the Renter and
                  the Kitchen Owner for security reasons. Any arrangements made
                  outside of the AirKitchenz platform are strictly prohibited.
                </p>

                <h3>7. Credit Card Authorization and Charges</h3>
                <p>
                  The Renter authorizes AirKitchenz to keep the credit cards
                  used for payment on file. In the event of damage, missing
                  items, or if costs exceed the deposit amount, the Renter
                  agrees that AirKitchenz has the right to charge the Renter’s
                  credit cards for the additional amount necessary to cover the
                  expenses incurred.
                </p>
                <p>
                  The Renter understands that this authorization includes
                  charging the credit cards for any costs related to damages,
                  missing items, or other expenses not covered by the initial
                  security deposit.
                </p>

                <h3>8. Communication Protocol</h3>
                <p>
                  The Renter agrees that all communications between the Renter
                  and kitchen owners must be conducted exclusively through the
                  AirKitchenz platform. Renters and kitchen owners are
                  prohibited from exchanging phone numbers, email addresses, or
                  other contact information outside of the AirKitchenz platform.
                </p>

                <h3>9. Rating System and Reviews</h3>
                <p>
                  AirKitchenz maintains a rating system that allows both kitchen
                  renters and kitchen owners to provide feedback and rate each
                  other based on their rental experience.
                </p>
                <p>Kitchen Renters will rate the kitchens based on:</p>
                <ul>
                  <li>Cleanliness of the kitchen</li>
                  <li>Functionality and condition of equipment</li>
                  <li>Accuracy of the kitchen listing description</li>
                  <li>Overall experience</li>
                </ul>
                <p>Kitchen Owners will rate the Kitchen Renters based on:</p>
                <ul>
                  <li>Cleanliness after use</li>
                  <li>Adherence to kitchen rules</li>
                  <li>Communication and responsiveness</li>
                  <li>Respect for the kitchen and equipment</li>
                </ul>

                <h3>10. Enforcement and Penalties</h3>
                <p>
                  Any party found to be in violation of the communication
                  protocol will be subject to suspension or permanent removal
                  from the AirKitchenz platform.
                </p>

                <h3>11. AirKitchenz Service Fee</h3>
                <p>
                  AirKitchenz charges a service fee of 15% of the total rental
                  cost for facilitating the transaction and providing platform
                  services.
                </p>

                <h3>12. Governing Law</h3>
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of the United States and the states
                  where the transactions take place.
                </p>

                <h3>13. Entire Agreement</h3>
                <p>
                  This Agreement constitutes the entire agreement between the
                  Renter and AirKitchenz regarding the rental of the kitchen and
                  supersedes all prior agreements and understandings, whether
                  written or oral.
                </p>

                <p>
                  By checking the box below, the Renter acknowledges that they
                  have read, understood, and agree to be bound by all terms and
                  conditions herein. Failure to comply with any provision of
                  this Agreement may result in termination of the rental
                  agreement and forfeiture of the Renter’s deposit.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Kitchen;
