import React, { useState, useEffect } from "react";
import "./FeaturesSection.css";
import KitchenImage from "../../assets/images/kitchen-rental.png";
import AIBuilderImage from "../../assets/images/ai-builder.png";
import IngredientImage from "../../assets/images/order.png";

const featuresData = [
  {
    id: 1,
    title: "Kitchen Rentals",
    description:
      "AirKitchenz helps restaurants monetize their kitchens during off-hours, giving startups and food entrepreneurs flexible, on-demand access to fully equipped, professional kitchens. Start, expand, and test new markets without costly build-outs. Find your perfect kitchen with us!",
    image: KitchenImage,
  },
  {
    id: 2,
    title: "AI Builder",
    additionalInfo: [
      "• AI Menu Builder: Enter your concept and cuisine type, and our AI will create a complete menu with ingredients, recipes, and a shopping list.",
      "• AI Menu Uploader: Upload your menu and go live with a fully integrated delivery system based on your rented hours. (Coming Soon)",
      "• AI Kitchen Matchmaker: Find the best location and kitchens for your business concept using our AI tools. (Coming Soon)",
    ],
    image: AIBuilderImage,
  },
  {
    id: 3,
    title: "Ingredient Ordering",
    description:
      "Connect directly with suppliers through our platform and find the best options for your needs. (Coming Soon)",
    image: IngredientImage,
  },
];

const FeaturesSection = () => {
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);

  const handleFeatureSelect = (index) => {
    setSelectedFeatureIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedFeatureIndex((prevIndex) =>
        prevIndex === featuresData.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Rotate every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const selectedFeature = featuresData[selectedFeatureIndex];

  return (
    <div className="features-section">
      <h2>Key Features of AirKitchenz</h2>
      <div className="feature-buttons">
        {featuresData.map((feature, index) => (
          <button
            key={feature.id}
            className={`feature-button ${
              selectedFeatureIndex === index ? "active" : ""
            }`}
            onClick={() => handleFeatureSelect(index)}
          >
            {feature.title}
          </button>
        ))}
      </div>
      <div className="feature-content">
        <img
          src={selectedFeature.image}
          alt={selectedFeature.title}
          className="feature-image"
        />
        <div className="feature-description">
          <p>{selectedFeature.description}</p>
          {selectedFeature.additionalInfo && (
            <div className="feature-additional-info">
              {selectedFeature.additionalInfo.map((info, index) => (
                <p key={index}>{info}</p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;