import React from "react";
import { Route, Routes } from "react-router-dom";
import { KitchenProvider } from "../../context/KitchenContext";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import UserMenuTabs from "../../components/UserMenuTabs/UserMenuTabs";
import ProfileDetails from "../../components/ProfileDetails/ProfileDetails";
import AddKitchen from "../../components/AddKitchen/AddKitchen";
import MyKitchens from "../../components/MyKitchens/MyKitchens";
import MyBookings from "../../components/MyBookings/MyBookings";
import MyReservations from "../../components/MyReservations/MyReservations";
import Calendar from "../../components/Calendar/Calendar";
import Invoices from "../../components/Invoices/Invoices";
import Settings from "../../components/Settings/Settings";
import "./Profile.css";

const Profile = () => {
  return (
    <div className="profile-page">
      <Navbar />
      <div className="profile-content">
        <div className="sidebar">
          <UserMenuTabs />
        </div>
        <div className="main-content">
          <KitchenProvider>
            <Routes>
              <Route path="/" element={<ProfileDetails />} />
              <Route path="add-kitchen" element={<AddKitchen editMode={false} />} />
              <Route path="edit-kitchen/:kitchenID" element={<AddKitchen editMode={true}  />} />
              <Route path="my-kitchens" element={<MyKitchens />} />
              <Route path="my-bookings" element={<MyBookings />} />
              <Route path="my-reservations" element={<MyReservations />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="settings" element={<Settings />} />
            </Routes>
          </KitchenProvider>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
