import React, { useState, useEffect, useRef } from "react";
import { useBooking } from "../../context/BookingContext";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./MyBookings.css";
import companyLogo from "../../assets/images/header-logo.png";

ReactModal.setAppElement("#root");

const MyBookings = () => {
  const { fetchUserBookings, user } = useBooking();
  const [bookings, setBookings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const invoiceRef = useRef();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const userBookings = await fetchUserBookings();
        setBookings(userBookings.reverse());
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };
    fetchBookings();
  }, [fetchUserBookings]);

  const openModal = (booking) => {
    setSelectedBooking(booking);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBooking(null);
    setIsModalOpen(false);
  };

  const formatTime = (time) => {
    const hours = String(Math.floor(time)).padStart(2, "0");
    return `${hours}:00`;
  };

  const handleDownloadPDF = () => {
    const input = invoiceRef.current;
    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`invoice_${selectedBooking.BookingID}.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  if (!user) {
    return (
      <p className="loading">
        Please log in or sign up to access your bookings.
      </p>
    );
  }

  if (!bookings || bookings.length === 0) {
    return <p className="loading">You currently do not have any bookings.</p>;
  }

  return (
    <div className="my-bookings-container">
      <h1>MY BOOKINGS</h1>
      <ul className="bookings-list">
        {bookings.map((booking) => (
          <li key={booking.BookingID} className="booking-item">
            <div className="booking-details">
              <h3>
                <Link to={`/kitchen/${booking.KitchenID}`}>
                  {booking.KitchenTitle || "Kitchen"}
                </Link>
              </h3>
              <p>
                <strong>Check-in:</strong> {booking.CheckinDate} <br />
                <strong>Start Time:</strong> {formatTime(booking.StartTime)}{" "}
              </p>
              <p>
                <strong>Check-out:</strong> {booking.CheckoutDate} <br />
                <strong>End Time:</strong> {formatTime(booking.EndTime)}{" "}
              </p>
              <p className={`status status-${booking.Status.toLowerCase()}`}>
                Status: {booking.Status || "Pending"}
              </p>
            </div>
            <div className="booking-price">
              <p>
                <strong>Total Amount:</strong> ${booking.TotalAmount.toFixed(2)}
              </p>
              <p>
                <strong>Booking Type:</strong> {booking.BookingType}
              </p>
              {booking.SecurityDeposit && (
                <p>
                  <strong>Security Deposit:</strong> $
                  {booking.SecurityDeposit.toFixed(2)}
                </p>
              )}
              <button
                className="invoice-button"
                onClick={() => openModal(booking)}
              >
                Invoice
              </button>
            </div>
          </li>
        ))}
      </ul>

      {selectedBooking && (
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Invoice Modal"
          className="booking-modal"
          overlayClassName="booking-modal-overlay"
        >
          <div className="booking-modal-content" ref={invoiceRef}>
            <div className="booking-modal-header">
              <img
                src={companyLogo}
                alt="Company Logo"
                className="booking-modal-logo"
              />
              <h2>Invoice</h2>
            </div>
            <div className="booking-modal-company-info">
              <h3>AirKitchenz Inc.</h3>
              <p>Email: contact@airkitchenz.com</p>
              <p>Phone: (424) 458-3232</p>
              {/* <p>Address: 1234 Main St, City, Country</p> */}
            </div>
            <div className="booking-modal-customer-info">
              <h4>Customer Details</h4>
              <p>
                <strong>Name:</strong> {user.firstName} {user.lastName}
              </p>
              <p>
                <strong>Email:</strong> {user.email}
              </p>
              <p>
                <strong>Phone:</strong> {user.phone || "N/A"}
              </p>
            </div>
            <table className="booking-modal-table">
              <thead>
                <tr>
                  <th>Check-in</th>
                  <th>Check-out</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {selectedBooking.CheckinDate} by{" "}
                    {formatTime(selectedBooking.StartTime)}
                  </td>
                  <td>
                    {selectedBooking.CheckoutDate} by{" "}
                    {formatTime(selectedBooking.EndTime)}
                  </td>
                  {/* <td>{calculateDuration(selectedBooking)}</td> */}
                  <td>
                    {selectedBooking.BookingType.toLowerCase() === "hour"
                      ? `${selectedBooking.TotalHours} ${
                          selectedBooking.TotalHours > 1 ? "hours" : "hour"
                        }`
                      : `${selectedBooking.TotalDays} ${
                          selectedBooking.TotalDays > 1 ? "days" : "day"
                        }`}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="booking-modal-summary">
              <div className="booking-modal-summary-item">
                <strong>Service Fee:</strong> $
                {selectedBooking.ServiceFee
                  ? selectedBooking.ServiceFee.toFixed(2)
                  : "0.00"}
              </div>
              {selectedBooking.SecurityDeposit && (
                <div className="booking-modal-summary-item">
                  <strong>Security Deposit:</strong> $
                  {selectedBooking.SecurityDeposit.toFixed(2)}
                </div>
              )}
              <div className="booking-modal-summary-item">
                <strong>Total Amount:</strong> $
                {selectedBooking.TotalAmount.toFixed(2)}
              </div>
            </div>
            <div className="booking-modal-instructions">
              <h4>Instructions</h4>
              <p>
                If you need to cancel your booking, please contact us at least
                24 hours in advance to receive a full refund. For more details,
                contact us at{" "}
                <a href="mailto:contact@airkitchenz.com?subject=Inquiry&body=Hi, I would like to contact regarding a booking.">
                  contact@airkitchenz.com
                </a>
              </p>
            </div>
          </div>
          <div className="booking-modal-footer">
            <button
              className="booking-modal-download"
              onClick={handleDownloadPDF}
            >
              Download PDF
            </button>
            <button className="booking-modal-close" onClick={closeModal}>
              Close
            </button>
          </div>
        </ReactModal>
      )}
    </div>
  );
};

export default MyBookings;
