import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./AboutUs.css";

const teamMembers = [
  {
    name: "Diego Leporini",
    role: "Co-Founder and CEO",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/DiegoMTT.jpg",
  },
  {
    name: "Kunal Doshi",
    role: "Co-Founder & Developer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/KunalMTT.jpeg",
  },
  {
    name: "Vaibhav Monpara",
    role: "Co-Founder & Developer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/VickMTT.jpg",
  },
  {
    name: "Vasu Lakhani",
    role: "Software Engineer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/VasuMTT.jpg",
  },
  {
    name: "Yash Gandhi",
    role: "Software Engineer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/YashMTT.jpg",
  },
  {
    name: "Adam Atwi",
    role: "Marketing Strategist",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/AdamMTT.jpg",
  },
];

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <Navbar />

      {/* About Section: Left image, right text */}
      <div className="about-header">
        <div className="aboutus-image-container">
          <img
            src={require("../../assets/images/aboutus-background.jpg")}
            alt="Chef working"
          />
        </div>
        <div className="text-container">
          <div className="section-header">ABOUT OUR COMPANY</div>
          <h2>AirKitchenz AI</h2>
          <p>
            AirKitchenz is a revolutionary platform that connects restaurant
            owners with unused hours of kitchen space to aspiring food
            entrepreneurs seeking affordable and flexible kitchen facilities
            during non-operational hours.
          </p>
          <p>
            Aiming to optimize the utilization of restaurant kitchens and create
            a win-win scenario for both restaurants and aspiring food
            entrepreneurs.
          </p>
        </div>
      </div>
      {/* Team Section */}
      <div className="team-header">
        <div className="section-header">MEET OUR TEAM</div>
        <h2>Meet the Minds Behind AirKitchenz AI</h2>
        <p>
          From visionary developers crafting our platform to co-founders driving
          our mission, to marketing specialists spreading the word—our team is
          the force turning kitchens into opportunities.
        </p>
      </div>

      <div className="team-section">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.image} alt={member.name} />
            <h4>{member.name}</h4>
            <p>{member.role}</p>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
