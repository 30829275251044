import React, { useState, useEffect, useRef } from "react";
import { useKitchen } from "../../context/KitchenContext";
import "./KitchenLocation.css";
import { FaMapMarkerAlt } from "react-icons/fa"; // Importing Font Awesome icon

// Load Google Maps script dynamically
const loadGoogleMapsScript = (callback) => {
  if (!document.querySelector(`[src*="maps.googleapis.com"]`)) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => {
      console.log("Google Maps script loaded.");
      callback();
    };
    script.onerror = () => {
      console.error("Error loading Google Maps script.");
    };
  } else {
    callback();
  }
};

const KitchenLocation = ({
  nextStep,
  prevStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const { updateKitchen, updateKitchenEditMode } = useKitchen();
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(34.0522); // Default to LA
  const [longitude, setLongitude] = useState(-118.2437); // Default to LA
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef(null); // Reference to the map container


   // Initialize state only once to prevent resets
   const isInitialized = useRef(false);
   const [mapReady, setMapReady] = useState(false);

   useEffect(() => {
    if (!isInitialized.current && kitchenData) {
      setAddress(kitchenData.address || "");
      setLatitude(kitchenData.latitude || 34.0522);
      setLongitude(kitchenData.longitude || -118.2437);
      isInitialized.current = true;
      setMapReady(true);
    }
  }, [kitchenData]);

  useEffect(() => {
    // Load Google Maps and Places API
    loadGoogleMapsScript(() => {
      if (window.google && window.google.maps) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("address-input"),
          { types: ["geocode"] }
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setLatitude(lat);
            setLongitude(lng);
            setAddress(place.formatted_address);

            // Update central kitchenData
            updateKitchenData({
              address: place.formatted_address,
              latitude: lat,
              longitude: lng,
            });

            // Update map position and marker
            if (map && marker) {
              map.setCenter({ lat, lng });
              marker.setPosition({ lat, lng });
            }
          }
        });

        // Define a modern map style (You can customize this JSON for different styles)
        const mapStyle = [
          {
            featureType: "water",
            stylers: [{ color: "#19a0d8" }],
          },
          {
            featureType: "administrative",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }, { weight: 6 }],
          },
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#e85113" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#efe9e4" }, { lightness: -40 }],
          },
          {
            featureType: "transit.station",
            stylers: [{ weight: 9 }, { hue: "#e85113" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }, { lightness: 10 }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#000000" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#efe9e4" }, { lightness: -25 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 18 }],
          },
          {
            featureType: "road.local",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 16 }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#696969" }],
          },
          {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#a5b076" }],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ];

        // Initialize the map
        const mapInstance = new window.google.maps.Map(mapRef.current, {
          center: { lat: latitude, lng: longitude },
          zoom: 15,
          styles: mapStyle,
          disableDefaultUI: false,
          zoomControl: true,
        });

        // Initialize the marker
        const markerInstance = new window.google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: mapInstance,
          draggable: true,
          title: "Drag me!",
          icon: {
            url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
          },
        });

        // Add click event to add a new marker
        mapInstance.addListener("click", (e) => {
            const clickedLocation = e.latLng;
            const lat = clickedLocation.lat();
            const lng = clickedLocation.lng();
            setLatitude(lat);
            setLongitude(lng);
            setAddress(""); // Clear address if user selects a location manually

          
            // Update central kitchenData
            updateKitchenData({ latitude: lat, longitude: lng, address: "" });

          if (marker) {
            marker.setPosition(clickedLocation);
          } else {
            const newMarker = new window.google.maps.Marker({
              position: clickedLocation,
              map: mapInstance,
              draggable: true,
              title: "Drag me!",
              icon: {
                url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
              },
            });

            newMarker.addListener("dragend", (event) => {
                const lat = event.latLng.lat();
                const lng = event.latLng.lng();
                setLatitude(lat);
                setLongitude(lng);
                updateKitchenData({ latitude: lat, longitude: lng });
            });

            setMarker(newMarker);
          }
        });

        // Marker drag event
        markerInstance.addListener("dragend", (event) => {
          const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            setLatitude(lat);
            setLongitude(lng);
            updateKitchenData({ latitude: lat, longitude: lng });
        });

        setMap(mapInstance);
        setMarker(markerInstance);
      } else {
        console.error("Google Maps API failed to load.");
      }
    });
  }, []);

  useEffect(() => {
    // Update map position when latitude/longitude changes
    if (map && marker) {
      map.setCenter({ lat: latitude, lng: longitude });
      marker.setPosition({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude, map, marker]);

  const handleNext = async (e) => {
    e.preventDefault();
    setLoading(true);

    const locationData = {
      address: address,
      latitude: latitude,
      longitude: longitude,
    };

    const updatedKitchenData = { ...kitchenData, ...locationData };

    try {
      if (editMode) {
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        await updateKitchen(updatedKitchenData);
      }
      // Update central kitchenData
      updateKitchenData(locationData);

      nextStep();
    } catch (error) {
      console.error("Failed to update location details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleNext} className="kitchen-location">
       <h2 className="form-title">{editMode ? "Edit Location" : "Location"}</h2>
      <div className="form-group">
        <label htmlFor="address-input">Address</label>
        <div className="input-with-icon">
          <FaMapMarkerAlt className="input-icon" />
          <input
            type="text"
            id="address-input"
            placeholder="Enter kitchen address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              updateKitchenData({ address: e.target.value });
            }}
          />
        </div>
      </div>

      <div ref={mapRef} className="map-view"></div>

      <div className="form-row">
        <div className="form-group">
          <label>Latitude</label>
          <input type="number" value={latitude || null } readOnly />
        </div>
        <div className="form-group">
          <label>Longitude</label>
          <input type="number" value={longitude || null} readOnly />
        </div>
      </div>

      <div className="navigation-buttons">
        <button type="button" onClick={prevStep} className="prev-btn">
          Previous
        </button>
        <button type="submit" className="next-btn" disabled={loading}>
          {loading ? "Saving..." : editMode ? "Update" : "Next"}
        </button>
        {loading && (
          <div className="loading-indicator">
            {editMode
              ? "Updating your location details..."
              : "Saving your location details..."}
          </div>
        )}
      </div>
    </form>
  );
};

export default KitchenLocation;
