import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config"; // Ensure this matches your setup

const BookingsTable = () => {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    throw new Error("No token found. Please log in.");
                }

                const response = await axios.get(`${config.apiBaseUrl}/api/admin/bookings`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBookings(response.data);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchBookings();
    }, []);

    const handleDelete = async (bookingID) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("No token found. Please log in.");
            }

            await axios.delete(`${config.apiBaseUrl}/api/admin/bookings/${bookingID}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setBookings((prevBookings) => prevBookings.filter((booking) => booking.BookingID !== bookingID));
        } catch (error) {
            console.error("Error deleting booking:", error);
        }
    };

    return (
        <div className="bookings-table">
            <h2>Bookings</h2>
            {loading ? (
                <p>Loading bookings...</p>
            ) : (
                <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>User</th>
                            <th>Kitchen</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking) => (
                            <tr key={booking.BookingID}>
                                <td>{booking.BookingID}</td>
                                <td>{booking.UserID}</td>
                                {/* <td>{booking.KitchenTitle}</td> */}
                                {/* <td>{booking.Date}</td> */}
                                <td>
                                    <button>Edit</button>
                                    <button onClick={() => handleDelete(booking.BookingID)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            )}
        </div>
    );
};

export default BookingsTable;
