import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useKitchen } from "../../context/KitchenContext";
import "./FeaturedKitchens.css";
import DefaultImage from "../../assets/images/image.png";

const FeaturedKitchens = () => {
  const [featuredKitchens, setFeaturedKitchens] = useState([]);
  const { fetchAllKitchens, allKitchens } = useKitchen();

  useEffect(() => {
    const fetchKitchens = async () => {
      try {
        const kitchens = await fetchAllKitchens();
        if (kitchens && kitchens.length > 0) {
          const sortedKitchens = kitchens.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setFeaturedKitchens(kitchens.slice(0, 3));
        }
      } catch (error) {
        console.error("Error fetching featured kitchens:", error);
      }
    };
    fetchKitchens();
  }, [fetchAllKitchens]);

  return (
    <div className="featured-kitchens">
      <h2>Featured Kitchens</h2>
      <div className="kitchens-container">
        {featuredKitchens.length > 0 ? (
          featuredKitchens.map((kitchen, index) => (
            <Link
              to={`/kitchen/${kitchen.KitchenID}`}
              key={index}
              className="kitchen-link"
            >
              <div className="featured-kitchen-card">
                <div className="kitchen-image-wrapper">
                  {kitchen.Images && kitchen.Images[0] ? (
                    <img src={kitchen.Images[0]} alt={kitchen.Title} />
                  ) : (
                    <img src={DefaultImage} alt={kitchen.Title} />
                  )}
                  <div className="kitchen-overlay">
                    <h3>{kitchen.Title || "Untitled Kitchen"}</h3>
                    <p>{kitchen.City || "Unknown Location"}</p>
                    <p className="price">
                      {kitchen.BookingType === "hour"
                        ? `$${kitchen.HourlyRate}/hour`
                        : `$${kitchen.DayRate}/day`}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p>No featured kitchens available</p>
        )}
      </div>
    </div>
  );
};

export default FeaturedKitchens;