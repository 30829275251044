import React, { useState, useEffect, useRef } from "react";
import { useKitchen } from "../../context/KitchenContext";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import KitchenCard from "../../components/KitchenCard/KitchenCard";
import MapComponent from "../../utils/MapComponent/MapComponent";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faMap, faSliders } from "@fortawesome/free-solid-svg-icons";
import { haversineDistance } from "../../utils/distance";
import "./BrowseKitchens.css";
import { BiCurrentLocation } from "react-icons/bi";
import config from "../../config";

// Utility function to load Google Maps script
const loadGoogleMapsScript = (callback) => {
  if (!document.querySelector(`[src*="maps.googleapis.com"]`)) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.mapApiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => callback();
  } else {
    callback();
  }
};

const BrowseKitchens = () => {
  const { fetchAllKitchens, searchKitchens, kitchens = [] } = useKitchen();

  // Initialize filters with consistent naming
  const [filters, setFilters] = useState({
    location: "",
    latitude: null,
    longitude: null,
    kitchenCategory: "",
    kitchenSize: "",
    checkinDate: "",
    checkoutDate: "",
    priceOrder: "asc",
    bookingType: "",
  });

  const [displayKitchens, setDisplayKitchens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCurrentLocationButton, setShowCurrentLocationButton] =
    useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(false);

  const locationInputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (locationInputRef.current && window.google && window.google.maps) {
        autocompleteRef.current = new window.google.maps.places.Autocomplete(
          locationInputRef.current,
          { types: ["geocode"] }
        );
        autocompleteRef.current.setFields(["geometry", "formatted_address"]);
        autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
      }
    };

    loadGoogleMapsScript(() => {
      initializeAutocomplete();
    });

    // Parse query parameters
    const searchParams = new URLSearchParams(location.search);
    const params = {
      location: searchParams.get("location") || "",
      latitude: searchParams.get("latitude")
        ? parseFloat(searchParams.get("latitude"))
        : null,
      longitude: searchParams.get("longitude")
        ? parseFloat(searchParams.get("longitude"))
        : null,
      kitchenCategory: searchParams.get("kitchenCategory") || "",
      kitchenSize: searchParams.get("kitchenSize") || "",
      checkinDate: searchParams.get("checkinDate") || "",
      checkoutDate: searchParams.get("checkoutDate") || "",
      priceOrder: searchParams.get("priceOrder") || "asc",
      bookingType: searchParams.get("bookingType") || "",
    };
    setFilters(params);
    // Decide whether to fetch all kitchens or perform a search based on filters
    const shouldFetchAll =
      params.latitude != null || params.kitchenCategory != "";
    if (!shouldFetchAll) {
      loadAllKitchens();
    } else {
      handleSearch(params);
    }
  }, [location.search]);

  // Function to load all kitchens
  const loadAllKitchens = async () => {
    setLoading(true);
    try {
      const allKitchens = await fetchAllKitchens();
      setDisplayKitchens(allKitchens);
    } catch (error) {
      console.error("Error loading all kitchens:", error);
      setDisplayKitchens([]);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle place selection from autocomplete
  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        const formattedAddress = place.formatted_address;
        setFilters((prevFilters) => ({
          ...prevFilters,
          location: formattedAddress,
          latitude: latitude,
          longitude: longitude,
        }));
        handleSearch({
          ...filters,
          location: formattedAddress,
          latitude: latitude,
          longitude: longitude,
        });
      }
    }
  };

  // Function to get user's current location
  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setUserLocation({ latitude, longitude });
          setLoadingLocation(false);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLoadingLocation(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  // Handlers for focus and blur events on location input
  const handleLocationInputFocus = () => {
    setShowCurrentLocationButton(true);
    getUserLocation();
  };

  const handleLocationInputBlur = () => {
    // Delay hiding the button to allow click event to register
    setTimeout(() => {
      setShowCurrentLocationButton(false);
    }, 200);
  };

  // Handler for pressing Enter key in location input
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Trigger place_changed to finalize the selection if Enter is pressed
      if (autocompleteRef.current) {
        handlePlaceSelect();
      }
    }
  };

  // New Function: Handle Geo Button Click
  const handleGeoButtonClick = () => {
    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Initialize Google Maps Geocoder service
          const geocoder = new window.google.maps.Geocoder();
          const latLng = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          };

          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK") {
              if (results && results.length > 0) {
                const formattedAddress = results[0].formatted_address;

                // Update filters with coordinates and address
                const newFilters = {
                  ...filters,
                  location: formattedAddress,
                  latitude: latitude,
                  longitude: longitude,
                };
                setFilters(newFilters);
                handleSearch(newFilters);
              } else {
                console.error("No results found for the given coordinates.");
                alert("Unable to find a location for your coordinates.");
              }
            } else {
              console.error("Geocoder failed due to: " + status);
              alert("Failed to retrieve location. Please try again.");
            }
            setLoadingLocation(false);
          });
        },
        (error) => {
          console.error("Error getting location:", error);
          alert(
            "Unable to retrieve your location. Please check your permissions."
          );
          setLoadingLocation(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
      alert("Geolocation is not supported by your browser.");
    }
  };

  // Main search handler
  const handleSearch = async (searchFilters = filters) => {
    const {
      location,
      latitude,
      longitude,
      kitchenCategory,
      kitchenSize,
      checkinDate,
      checkoutDate,
      priceOrder,
      bookingType,
    } = searchFilters;

    const hasLocation = latitude !== null && longitude !== null;
    const hasKitchenCategory = kitchenCategory.trim() !== "";

    // If no search criteria, fetch all kitchens
    if (!hasLocation && !bookingType && !hasKitchenCategory) {
      console.log("No meaningful filters provided. Fetching all kitchens.");
      loadAllKitchens();
      return;
    }

    setLoading(true);

    try {
      // Construct cleaned filters based on provided criteria
      const cleanedFilters = {};

      if (hasLocation) {
        cleanedFilters.latitude = latitude;
        cleanedFilters.longitude = longitude;
      }

      if (hasKitchenCategory) {
        cleanedFilters.kitchenCategory = kitchenCategory;
      }

      if (kitchenSize) {
        cleanedFilters.kitchenSize = kitchenSize;
      }

      if (checkinDate) {
        cleanedFilters.checkinDate = checkinDate;
      }

      if (checkoutDate) {
        cleanedFilters.checkoutDate = checkoutDate;
      }

      if (priceOrder) {
        cleanedFilters.priceOrder = priceOrder;
      }

      if (bookingType) {
        cleanedFilters.bookingType = bookingType;
      }

      // console.log("Cleaned Filters:", cleanedFilters);

      // Proceed with the API call using cleaned filters
      const filteredKitchens = (await searchKitchens(cleanedFilters)) || [];

      // Calculate distance for each kitchen only if location is provided
      const kitchensWithDistance = filteredKitchens.map((kitchen) => {
        if (hasLocation) {
          const distance = haversineDistance(
            latitude,
            longitude,
            kitchen.Latitude,
            kitchen.Longitude
          );
          return {
            ...kitchen,
            distance: distance.toFixed(2),
          };
        }
        return { ...kitchen, distance: null };
      });

      setDisplayKitchens(kitchensWithDistance);
    } catch (error) {
      console.error("Error during search:", error);
      setDisplayKitchens([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="browse-page">
      <Navbar />
      <div className="browse-page-content">
        {/* Search Bar */}
        <div className="browse-search-bar">
          {/* Line 1: Location Input */}
          <div className="autocomplete-container">
            <input
              type="text"
              ref={locationInputRef}
              placeholder="Provide a Location"
              value={filters.location}
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  location: e.target.value,
                  latitude: null,
                  longitude: null,
                }))
              }
              onFocus={handleLocationInputFocus}
              onBlur={handleLocationInputBlur}
              onKeyDown={handleKeyDown}
            />
            <button
              className="geo-button"
              onClick={handleGeoButtonClick}
              disabled={loadingLocation}
              aria-label="Use my current location"
            >
              {loadingLocation ? (
                <span className="spinner"></span>
              ) : (
                <BiCurrentLocation />
              )}
            </button>
          </div>

          {/* Line 2: Date Inputs */}
          <div className="date-inputs">
            <input
              type="date"
              value={filters.checkinDate}
              onChange={(e) =>
                setFilters({ ...filters, checkinDate: e.target.value })
              }
              placeholder="Check-In Date"
            />
            <input
              type="date"
              value={filters.checkoutDate}
              onChange={(e) =>
                setFilters({ ...filters, checkoutDate: e.target.value })
              }
              placeholder="Check-Out Date"
            />
          </div>

          {/* Line 3: Icons for Search, Map, and Filter */}
          <div className="icon-group">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                handleSearch(filters);
              }}
              className="search-icon-link"
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link>
            <Link
              className="view-map-button"
              onClick={() => {
                if (mapRef.current) {
                  mapRef.current.scrollIntoView({ behavior: "smooth" });
                }
              }}
              aria-label="View on Map"
              title="View on Map"
            >
              <FontAwesomeIcon icon={faMap} />
            </Link>
            <Link
              className="filter-toggle-button"
              onClick={() => setShowFilters(!showFilters)}
              aria-label="Show Filters"
              title="Show Filters"
            >
              <FontAwesomeIcon icon={faSliders} />
            </Link>
          </div>
        </div>

        {/* Conditionally Render Filters */}
        {showFilters && (
          <div className="filters">
            <select
              value={filters.priceOrder}
              onChange={(e) => {
                const updatedFilters = {
                  ...filters,
                  priceOrder: e.target.value,
                };
                setFilters(updatedFilters);
                handleSearch(updatedFilters);
              }}
            >
              <option value="asc">Price Low to High</option>
              <option value="desc">Price High to Low</option>
            </select>
            <select
              value={filters.kitchenCategory}
              onChange={(e) => {
                const updatedFilters = {
                  ...filters,
                  kitchenCategory: e.target.value,
                };
                setFilters(updatedFilters);
                handleSearch(updatedFilters);
              }}
            >
              <option value="">Kitchen Category</option>
              <option value="commercial">Commercial Kitchen</option>
              <option value="restaurant">Restaurant Kitchen</option>
              <option value="ghost">Ghost Kitchen</option>
              <option value="bakery">Bakery Kitchen</option>
              <option value="private">Private Kitchen</option>
              <option value="all">All Purpose Kitchen</option>
            </select>
            <select
              value={filters.bookingType}
              onChange={(e) => {
                const updatedFilters = {
                  ...filters,
                  bookingType: e.target.value,
                };
                setFilters(updatedFilters);
                handleSearch(updatedFilters);
              }}
            >
              <option value="">Booking Type</option>
              <option value="hour">Hourly</option>
              <option value="day">Daily</option>
            </select>
          </div>
        )}

        {/* Kitchen List */}
        <div className="kitchen-list">
          {loading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p className="loading-text">
                Looking for the perfect kitchen for you...
              </p>
            </div>
          ) : displayKitchens.length > 0 ? (
            displayKitchens.map((kitchen) => (
              <div className="kitchen-card-container" key={kitchen.KitchenID}>
                <KitchenCard kitchen={kitchen} distance={kitchen.distance} />
              </div>
            ))
          ) : (
            <p>No kitchens found matching your criteria.</p>
          )}
        </div>

        {/* Map Component */}
        <div className="map-container" ref={mapRef}>
          <MapComponent kitchens={displayKitchens} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BrowseKitchens;
