import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import HeroSection from "../../components/HeroSection/HeroSection";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import FeaturedKitchens from "../../components/FeaturedKitchens/FeaturedKitchens";
import ContactUs from "../../components/ContactUs/ContactUs";
import "./Home.css";

const Home = () => {
  const [user, setUser] = useState(null);
  return (
    <div className="home-container">
      <Navbar user={user} setUser={setUser} />
      <HeroSection />
      <HowItWorks />
      <FeaturesSection />
      <FeaturedKitchens />
      {/* <ContactUs /> */}
      <Footer />
    </div>
  );
};

export default Home;
