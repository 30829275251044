import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
const BookingContext = createContext();
export const useBooking = () => useContext(BookingContext);

export const BookingProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Funtion to initiate the stripe payment
  const initiateStripePayment = async (bookingDetails) => {
    console.log("[BookingContext] Initiating payment");

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/stripe/create-checkout-session`,
        {
          ...bookingDetails,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      window.location.href = response.data.url; // Redirecting to Stripe Checkout
    } catch (error) {
      console.error("Error creating Stripe session:", error);
      alert("Failed to initiate payment. Please try again.");
    }
  };

  // Function to fetch user's bookings
  const fetchUserBookings = async () => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/bookings/my-bookings`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data.bookings;
    } catch (error) {
      console.error("Error fetching user bookings:", error);
      throw error;
    }
  };

  // Function to fetch owner's reservations
  const fetchOwnerReservations = async () => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/bookings/my-reservations`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data.reservations;
    } catch (error) {
      console.error("Error fetching reservations:", error);
      throw error;
    }
  };

  // Function to check kitchen availability before booking.
  const checkKitchenAvailability = async (
    kitchenID,
    checkinDate,
    checkoutDate,
    startTime,
    endTime
  ) => {
    try {
      console.log("Kitchen id in context", kitchenID);
      const response = await axios.post(
        `${config.apiBaseUrl}/api/bookings/check-availability`,
        {
          kitchenID,
          checkinDate,
          checkoutDate,
          startTime,
          endTime,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error checking availability:",
        error.response?.data || error.message
      );
      throw error.response?.data || { error: "Failed to check availability." };
    }
  };

  return (
    <BookingContext.Provider
      value={{
        initiateStripePayment,
        fetchUserBookings,
        fetchOwnerReservations,
        checkKitchenAvailability,
        user,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
