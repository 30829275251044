import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./FAQs.css";

// FAQ Data
const faqs = [
  {
    question: "What is AirKitchenz?",
    answer:
      "AirKitchenz is a marketplace that connects underutilized restaurant kitchens with food entrepreneurs and startups. We allow restaurants to rent their kitchens during off-hours, generating extra income, while giving food businesses a flexible, low-cost way to launch or expand.",
  },
  {
    question: "How does AirKitchenz work for restaurants?",
    answer:
      "If you’re a restaurant owner, you can list your kitchen on AirKitchenz and rent it out during hours when it’s not in use. This helps you generate additional revenue without any extra work, by turning your idle kitchen space into a valuable asset.",
  },
  {
    question: "How does AirKitchenz benefit food startups?",
    answer:
      "Food startups can use AirKitchenz to rent fully equipped kitchen spaces, allowing them to launch their businesses without the high costs of building their own kitchen. Once you rent a kitchen, you can go live immediately, start taking orders, and make money through our integrated delivery and supply tools.",
  },
  {
    question: "What makes AirKitchenz different from other kitchen solutions?",
    answer:
      "Unlike cloud kitchens or shared spaces, AirKitchenz doesn’t require building new kitchen infrastructure. We use existing kitchens, which lowers costs for both restaurant owners and entrepreneurs. Our platform also provides a complete solution with integrated delivery systems and food supply ordering.",
  },
  {
    question: "Who can rent a kitchen through AirKitchenz?",
    answer:
      "Any food business or startup can rent a kitchen through AirKitchenz, from food trucks to catering companies and small food startups. Whether you need space for a few hours or on a regular basis, we provide flexible options that fit your needs.",
  },
  {
    question: "What kinds of tools does AirKitchenz provide?",
    answer:
      "In addition to kitchen rentals, AirKitchenz offers an integrated delivery system, allowing you to take orders and start making money right away. We also provide AI tools to help you create and manage your food business, including supply chain management, so you can order ingredients directly through our platform and more.",
  },
  {
    question: "How much does it cost to rent a kitchen through AirKitchenz?",
    answer:
      "The cost of renting a kitchen varies depending on the location, equipment, and hours of use. AirKitchenz provides flexible pricing options, allowing you to choose a kitchen that fits your budget and schedule.",
  },
  {
    question: "What areas does AirKitchenz currently serve?",
    answer:
      "AirKitchenz is currently operating in Los Angeles and Orange County, with plans to expand to other major cities across the U.S.",
  },
  {
    question: "How do I list my kitchen on AirKitchenz?",
    answer:
      "If you’re a restaurant owner, you can easily list your kitchen on our platform by signing up and filling in your kitchen details, including availability and pricing. Our team will help you set everything up to start generating income from your unused kitchen hours.",
  },
  {
    question: "How do I get started with AirKitchenz?",
    answer:
      "To get started, simply sign up on our platform. If you have a restaurant, list your kitchen and start earning. If you’re a food business, search for a kitchen that fits your needs, rent it, and start operating right away.",
  },
];

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between open and close
  };

  return (
    <div className="FAQs-main-container">
      <Navbar />
      <div className="faq-container">
        <h2>Frequently Asked Questions</h2>
        <p className="faq-message">
          <strong>Got Questions? We've Got Answers!</strong>
        </p>
        <p className="faq-description">
          Explore our FAQs to learn how <strong>AirKitchenz AI</strong> is
          transforming the food industry by connecting restaurant owners with
          food entrepreneurs. Whether you're looking to optimize your kitchen's
          potential or start your culinary journey, we’re here to help!
        </p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className={`faq-question ${
                  activeIndex === index ? "selected" : ""
                }`}
                onClick={() => toggleFAQ(index)}
              >
                <span>{faq.question}</span>
                <span
                  className={`arrow ${activeIndex === index ? "open" : ""}`}
                >
                  &#9660;
                </span>
              </div>
              <div
                className={`faq-answer ${activeIndex === index ? "show" : ""}`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>

        {/* Got more questions section */}
        <div className="faqs-got-questions">
          <p>Got more questions? We would be happy to help you out!</p>
          <a href="mailto:info@airkitchenz.com?subject=Inquiry&body=Hi, I would like to learn more about AirKitchenz.">
            <button className="get-in-touch-btn">Get in touch</button>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
