// src/components/MapComponent/MapComponent.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./MapComponent.css";

const MapComponent = ({ kitchens }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.google && kitchens.length > 0) {
      const firstValidKitchen = kitchens.find(
        (kitchen) =>
          typeof kitchen.Latitude === "number" &&
          typeof kitchen.Longitude === "number"
      );

      if (!firstValidKitchen) {
        console.error("No kitchen has valid latitude and longitude.");
        return;
      }

      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: firstValidKitchen.Latitude, lng: firstValidKitchen.Longitude },
        zoom: 10,
      });

      // Initialize a single InfoWindow instance
      const infoWindow = new window.google.maps.InfoWindow();

      kitchens.forEach((kitchen) => {
        const { Latitude, Longitude, Title, KitchenID, BookingType, HourlyRate, DayRate } = kitchen;

        if (typeof Latitude !== "number" || typeof Longitude !== "number") {
          console.warn(`Invalid coordinates for kitchen ID: ${KitchenID}`);
          return; // Skip this kitchen
        }

        const rate = BookingType === "hour" ? `$${HourlyRate} per hour` : `$${DayRate} per day`;

        const marker = new window.google.maps.Marker({
          position: { lat: Latitude, lng: Longitude },
          map: map,
          title: Title,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Custom marker icon
          },
        });

        // Mouseover event to display the kitchen's rate with bubble styling
        marker.addListener("mouseover", () => {
          const content = `
            <div class="info-bubble">
              <h3 class="info-bubble-title">${Title}</h3>
              <p class="info-bubble-rate"><strong>Rate:</strong> ${rate}</p>
            </div>
          `;
          infoWindow.setContent(content);
          infoWindow.open(map, marker);
        });

        // Mouseout event to close the InfoWindow
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });

        // Click event to navigate to the kitchen's detail page
        marker.addListener("click", () => {
          navigate(`/kitchen/${KitchenID}`);
        });
      });

      // Adjust map bounds to fit all markers
      const bounds = new window.google.maps.LatLngBounds();
      kitchens.forEach((kitchen) => {
        const { Latitude, Longitude } = kitchen;
        if (typeof Latitude === "number" && typeof Longitude === "number") {
          bounds.extend({ lat: Latitude, lng: Longitude });
        }
      });
      map.fitBounds(bounds);
    }
  }, [kitchens, navigate]);

  return (
    <div id="map" className="map-component">
      {/* Google Map will render here */}
    </div>
  );
};

export default MapComponent;
