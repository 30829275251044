import React, { useState } from "react";
import KitchensTable from "../../components/Admin/KitchensTable";
import UsersTable from "../../components/Admin/UsersTable";
import BookingsTable from "../../components/Admin/BookingsTable";
import { Link } from "react-router-dom";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("kitchens");

  const renderContent = () => {
    switch (activeTab) {
      case "kitchens":
        return <KitchensTable />;
      case "users":
        return <UsersTable />;
      case "bookings":
        return <BookingsTable />;
      default:
        return <KitchensTable />;
    }
  };

  return (
    <div className="admin-dashboard">
      <aside className="admin-sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li
            onClick={() => setActiveTab("kitchens")}
            className={activeTab === "kitchens" ? "active" : ""}
          >
            Kitchens
          </li>
          <li
            onClick={() => setActiveTab("users")}
            className={activeTab === "users" ? "active" : ""}
          >
            Users
          </li>
          <li
            onClick={() => setActiveTab("bookings")}
            className={activeTab === "bookings" ? "active" : ""}
          >
            Bookings
          </li>
        </ul>
        <div className="add-blog-link">
          <Link to="/admin/add-blog">Add Blog</Link>
        </div>
      </aside>
      <main className="admin-main-content">
        <header className="admin-topbar"></header>
        <section className="admin-content">{renderContent()}</section>
      </main>
    </div>
  );
};

export default AdminDashboard;