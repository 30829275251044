import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./KitchenWaitingList.css";
import config from "../../config";

const KitchenWaitingList = () => {
  const navigate = useNavigate();
  const [isSeeking, setIsSeeking] = useState(true); 
  const [seekerData, setSeekerData] = useState({
    fullName: "",
    location: "",
    purpose: "",
    equipmentNeeds: "",
    specificDates: "",
    notes: "",
  });
  const [waitingList, setWaitingList] = useState([]);

  // Fetch the waiting list from the backend
  const fetchWaitingList = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/fetch-waiting-list`); 
      setWaitingList(response.data.waitingList);
    } catch (error) {
      console.error("Error fetching waiting list:", error);
    }
  };

  const handleChange = (e) => {
    setSeekerData({ ...seekerData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Making call");
      // Send the seekerData in the request
      const response = await axios.post(
        `${config.apiBaseUrl}/api/add-waiting-list`,
        seekerData
      );
      console.log(response.data);
      // Reset the form data
      setSeekerData({
        fullName: "",
        location: "",
        purpose: "",
        equipmentNeeds: "",
        specificDates: "",
        notes: "",
      });

      alert("You have been added to the waiting list!");
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("Failed to add you to the waiting list.");
    }
  };

  const handleViewKitchen = () => {
    navigate("/profile/add-kitchen");
  };

  return (
    <div className="waiting-list-container">
      <Navbar />
      <div className="waiting-list-main">
        <h2>Kitchen Matchmaker - Waiting List</h2>
        <div className="waiting-list-options">
          <button onClick={() => setIsSeeking(true)}>
            Kitchen Seekers: Register
          </button>
          <button
            onClick={() => {
              setIsSeeking(false);
              fetchWaitingList();
            }}
          >
            Show Waiting List
          </button>
        </div>

        {isSeeking && (
          <>
            <form onSubmit={handleSubmit} className="seeker-form">
              <h3 className="form-title">Register as a Kitchen Seeker</h3>
              <div className="form-row">
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    value={seekerData.fullName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Location</label>
                  <input
                    type="text"
                    name="location"
                    value={seekerData.location}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Specific Dates</label>
                  <input
                    type="date"
                    name="specificDates"
                    value={seekerData.specificDates}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Equipment Needs</label>
                  <input
                    type="text"
                    name="equipmentNeeds"
                    value={seekerData.equipmentNeeds}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Purpose</label>
                  <textarea
                    name="purpose"
                    value={seekerData.purpose}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="form-group">
                  <label>Notes</label>
                  <textarea
                    name="notes"
                    value={seekerData.notes}
                    onChange={handleChange}
                  />
                </div> */}
              </div>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>

            {/* Added Message Below the Form */}
            <div className="register-message">
              <p>
                Own a kitchen?{" "}
                <span
                  className="register-link"
                  onClick={handleViewKitchen}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleViewKitchen();
                  }}
                >
                  Register your kitchen now
                </span>{" "}
                to match with kitchen seekers!
              </p>
            </div>
          </>
        )}

        {!isSeeking && (
          <div className="waiting-list">
            <h3>Waiting List:</h3>
            {waitingList.length === 0 ? (
              <p>No seekers have registered yet.</p>
            ) : (
              waitingList.map((profile, index) => (
                <div
                  key={index}
                  className="waiting-list-item"
                  onClick={() => navigate("/profile/add-kitchen")}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") navigate("/profile/add-kitchen");
                  }}
                >
                  <div className="waiting-list-details">
                    <strong>Name:</strong> {profile.fullName}
                    <br />
                    <strong>Location:</strong> {profile.location}
                    <br />
                    <strong>Purpose:</strong> {profile.purpose}
                    <br />
                    <strong>Equipment Needs:</strong> {profile.equipmentNeeds}
                    <br />
                    {/* <strong>Notes:</strong> {profile.notes || "N/A"} */}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default KitchenWaitingList;
