import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import config from "../../config";
import "./SignupModal.css";
import logo from "../../assets/images/header-logo.png";

const SignupModal = ({ onClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    // const normalizedEmail = email.trim().toLowerCase();

    // Password match validation
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Clear any previous error
    setError("");

    try {
      // Make the API request to the backend
      const response = await axios.post(
        `${config.apiBaseUrl}/api/auth/signup`,
        { firstName, lastName, email, password } // Sending user data to the backend
      );

      // Success: show message and clear fields
      setSuccess("Sign up successful! You can now log in.");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");

      // Store the UserID if necessary for future requests
      localStorage.setItem("userID", response.data.userID);
    } catch (error) {
      console.error(
        "Sign-up error:",
        error.response ? error.response.data : error.message
      );

      // Display a detailed error message
      setError(error.response?.data?.message || "Error signing up. Try again.");
    }
  };

  return (
    <div className="signup-modal__overlay">
      <div className="signup-modal__container" role="dialog" aria-modal="true">
        <button
          className="signup-modal__close-btn"
          onClick={onClose}
          aria-label="Close Signup Modal"
        >
          &times;
        </button>

        <div className="signup-modal__header">
          <a href="/" className="signup-modal__logo-link">
            <img src={logo} alt="Logo" className="signup-modal__logo-image" />
          </a>
          <h3 className="signup-modal__title">Create an Account</h3>
        </div>

        {error && <p className="signup-modal__error-message">{error}</p>}
        {success && <p className="signup-modal__success-message">{success}</p>}

        {!success && (
          <form className="signup-modal__form" onSubmit={handleSignUp}>
            <div className="signup-modal__form-group">
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="signup-modal__input"
                aria-label="First Name"
              />
            </div>
            <div className="signup-modal__form-group">
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="signup-modal__input"
                aria-label="Last Name"
              />
            </div>
            <div className="signup-modal__form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="signup-modal__input"
                aria-label="Email Address"
              />
            </div>
            <div className="signup-modal__form-group password-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="signup-modal__input"
                aria-label="Password"
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <div className="signup-modal__form-group">
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="signup-modal__input"
                aria-label="Confirm Password"
              />
            </div>
            <button type="submit" className="signup-modal__signup-btn">
              Sign Up
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignupModal;
