import React, { useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import config from "../../config";
import "./AddBlog.css";

const AddBlog = () => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("date", date);
    formData.append("content", content);
    formData.append("image", image);

    try {
      await axios.post(`${config.apiBaseUrl}/api/blogs/add-blog`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("Blog added successfully!");
      setTitle("");
      setDate("");
      setContent("");
      setImage(null);
    } catch (error) {
      console.error("Error adding blog:", error);
      alert("Failed to add the blog. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="add-blog-container">
      <h1>Add a New Blog</h1>
      <form className="add-blog-form" onSubmit={handleSubmit}>
        <div className="blog-form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter blog title"
            required
          />
        </div>

        <div className="blog-form-group">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>

        <div className="blog-form-group">
          <label htmlFor="content">Content</label>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            // placeholder="Write the blog content here..."
          />
        </div>

        <div className="blog-form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
            required
          />
        </div>

        <div className="form-actions">
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Adding Blog..." : "Add Blog"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBlog;
