// KitchenImages.jsx
import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
import { useKitchen } from "../../context/KitchenContext";
import "./KitchenImages.css";
import config from "../../config";

const KitchenImages = ({
  nextStep,
  prevStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const [imageEntries, setImageEntries] = useState([
    { id: Date.now(), file: null, preview: "", category: "" },
  ]);
  const { updateKitchen, updateKitchenEditMode } = useKitchen();
  const [loading, setLoading] = useState(false);
  const isInitialized = useRef(false);
  // Handle adding a new image entry row
  useEffect(() => {
    if (!isInitialized.current && kitchenData) {
      if (editMode && kitchenData.images && kitchenData.images.length > 0) {
        // Initialize imageEntries with existing images
        setImageEntries(
          kitchenData.images.map((url, index) => ({
            id: index,
            url,
            file: null,
            preview: url,
            category: "", // Adjust if you have categories saved for images
          }))
        );
      } else {
        // Initialize with an empty entry for adding new images
        setImageEntries([{ id: Date.now(), file: null, preview: "", category: "" }]);
      }
      isInitialized.current = true;
    }
  }, [editMode, kitchenData]);



  const handleAddImageEntry = () => {
    setImageEntries([
      ...imageEntries,
      {
        id: Date.now(),
        file: null,
        preview: "",
        category: "",
      },
    ]);
  };

  // Handle changes in image input
  const handleImageChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      setImageEntries((prevEntries) =>
        prevEntries.map((entry) =>
          entry.id === id ? { ...entry, file, preview } : entry
        )
      );
    }
  };

  // Handle changes in category input
  const handleCategoryChange = (id, e) => {
    const category = e.target.value;
    setImageEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id ? { ...entry, category } : entry
      )
    );
  };

  // Remove image preview and corresponding entry data
  const handleRemoveImagePreview = (id) => {
    const entryToRemove = imageEntries.find((entry) => entry.id === id);
    if (entryToRemove) {
      if (entryToRemove.preview) {
        URL.revokeObjectURL(entryToRemove.preview);
      }
      setImageEntries((prevEntries) =>
        prevEntries.filter((entry) => entry.id !== id)
      );
    }
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newImages = imageEntries.filter((entry) => entry.file);
    const existingImages = imageEntries
      .filter((entry) => !entry.file && entry.url)
      .map((entry) => entry.url);

    const formData = new FormData();
    newImages.forEach((entry) => {
      if (entry.file) {
        formData.append("images", entry.file);
      }
    });

    try {

      let uploadedImageUrls = [];
      if (newImages.length > 0) {
        const response = await axios.post(
          `${config.apiBaseUrl}/api/kitchens/upload-images`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        uploadedImageUrls = response.data.imageUrls;
      }

      // Combine existing images with newly uploaded images
      const allImageUrls = [...existingImages, ...uploadedImageUrls];

      const imageData = { images: allImageUrls };

      // Merge the new image data with the existing kitchenData
      const updatedKitchenData = { ...kitchenData, ...imageData };

      if (editMode) {
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        await updateKitchen(updatedKitchenData);
      }
      // Update central kitchenData
      updateKitchenData(imageData);

      nextStep();
    } catch (error) {
      console.error("Failed to upload images:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Cleanup object URLs on unmount
  useEffect(() => {
    return () => {
      imageEntries.forEach((entry) => {
        if (entry.preview) {
          URL.revokeObjectURL(entry.preview);
        }
      });
    };
  }, [imageEntries]);

  return (
    <form onSubmit={handleNext} className="kitchen-images">
      <h2>{editMode ? "Edit Images" : "Upload Images"}</h2>

      {/* Image Previews */}
      <div className="image-preview-container">
        {imageEntries.map(
          (entry) =>
            entry.preview && (
              <div key={entry.id} className="image-preview">
                <img src={entry.preview} alt={`Preview ${entry.id}`} />
                <button
                  type="button"
                  className="remove-image-btn"
                  onClick={() => handleRemoveImagePreview(entry.id)}
                  aria-label="Remove Image Preview"
                >
                  &times;
                </button>
              </div>
            )
        )}
      </div>

      {/* Image Entry Rows */}
      <div className="image-entries">
        {imageEntries.map((entry) => (
          <div key={entry.id} className="image-entry">
            <div className="field-group">
              <div className="images-form-group">
                <label>Upload Image</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(entry.id, e)}
                />
              </div>
              <div className="images-form-group">
                <label>Select Kitchen Category</label>
                <select
                  value={entry.category}
                  onChange={(e) => handleCategoryChange(entry.id, e)}
                >
                  <option value="">Image Category</option>
                  <option value="general">General Area</option>
                  <option value="cooking">Cooking Area</option>
                  <option value="food-prep">Food Preparation Area</option>
                  <option value="dishroom">Dishroom Area</option>
                  <option value="storage">Storage Area</option>
                  <option value="refrigerator">Refrigerator Area</option>
                  {/* Add more categories as needed */}
                </select>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Add Image Button */}
      <button
        type="button"
        className="add-image-btn"
        onClick={handleAddImageEntry}
      >
        Add Image
      </button>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button type="button" onClick={prevStep} className="prev-btn">
          Previous
        </button>
        <button type="submit" className="next-btn">
          {loading ? "Saving..." : editMode ? "Update" : "Next"}
        </button>
      </div>

      {loading && (
        <div className="loading-indicator">
          {editMode
            ? "Updating your image details..."
            : "Saving your image details..."}
        </div>
      )}
    </form>
  );
};

export default KitchenImages;
