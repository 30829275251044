import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config"; // Ensure this matches your setup

const UsersTable = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    throw new Error("No token found. Please log in.");
                }

                const response = await axios.get(`${config.apiBaseUrl}/api/admin/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUsers(response.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    const handleDelete = async (userID) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("No token found. Please log in.");
            }

            await axios.delete(`${config.apiBaseUrl}/api/admin/users/${userID}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUsers((prevUsers) => prevUsers.filter((user) => user.UserID !== userID));
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    return (
        <div className="users-table">
            <h2>Users</h2>
            {loading ? (
                <p>Loading users...</p>
            ) : (
                <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>FirstName</th>
                            <th>LastName</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.UserID}>
                                <td>{user.FirstName}</td>
                                <td>{user.LastName}</td>
                                <td>{user.Email}</td>
                                <td>{user.Role}</td>
                                <td>
                                    <button>Edit</button>
                                    <button onClick={() => handleDelete(user.UserID)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            )}
        </div>
    );
};

export default UsersTable;
