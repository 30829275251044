import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import "./Blogs.css";
import config from "../../config";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/blogs`);
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="blogs-main-container">
      <Navbar />
      <div className="blogs-page">
        <h1>Our Blogs</h1>
        {loading ? (
          <div className="loading-indicator">Loading...</div>
        ) : blogs.length === 0 ? (
          <div className="no-blogs-message">
            <p>No blogs available at the moment. Please check back later!</p>
          </div>
        ) : (
          <div className="blogs-grid">
            {blogs.map((blog) => (
              <Link
                to={`/blogs/${blog.BlogID}`}
                key={blog.BlogID}
                className="blog-card"
              >
                <img
                  src={blog.imageUrl}
                  alt={blog.title}
                  className="blog-image"
                />
                <div className="blog-content">
                  <h2 className="blog-title">{blog.title}</h2>
                  <p className="blog-date">
                    {new Date(blog.date).toLocaleDateString()}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
